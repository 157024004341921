import * as React from "react"

type UseNavigatorOnLineOptions = {
  onOnline?: () => void
  onOffline?: () => void
}

const getNavigatorOnline = () =>
  typeof navigator !== "undefined" && typeof navigator.onLine === "boolean"
    ? navigator.onLine
    : true

export function useNavigatorOnLine(options: UseNavigatorOnLineOptions = {}) {
  const { onOnline, onOffline } = options
  const [isOnline, setIsOnline] = React.useState(getNavigatorOnline())

  React.useEffect(() => {
    const handleStatusChange = () => {
      const nextIsOnline = getNavigatorOnline()
      setIsOnline(nextIsOnline)
      if (nextIsOnline) {
        onOnline?.()
      } else {
        onOffline?.()
      }
    }

    window.addEventListener("online", handleStatusChange)
    window.addEventListener("offline", handleStatusChange)

    return () => {
      window.removeEventListener("online", handleStatusChange)
      window.removeEventListener("offline", handleStatusChange)
    }
  }, [onOnline, onOffline])

  return isOnline
}
