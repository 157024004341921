import { FiPlus } from "react-icons/fi"
import { Dialog, DialogContent, DialogTitle } from "~/components/ui"
import { removeDuplicateFieldsAndSort } from "../utils/clientHelpers"
import { Field } from "./../pages/Notes/types"

interface AddFieldModalProps {
  show: boolean
  onClose: () => void
  onAddField: (field: Field) => void
  existingFields: Field[]
  availableFields: Field[]
}

const AddFieldModal = ({
  show,
  onClose,
  onAddField,
  existingFields,
  availableFields,
}: AddFieldModalProps) => {
  const filteredFields = availableFields.filter(
    (field) =>
      !existingFields.some(
        (f) => f.name.toLowerCase() === field.name.toLowerCase()
      )
  )

  const uniqueFilteredFields = removeDuplicateFieldsAndSort(filteredFields, [
    "address",
    "zip",
    "city",
    "comment",
  ])

  return (
    <Dialog
      open={show}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          onClose()
        }
      }}
    >
      <DialogTitle className="sr-only">Add client field</DialogTitle>

      <DialogContent
        closeable={false}
        className="rounded-[1.125rem] p-0 bg-popover gap-0 sm:w-[22rem]"
      >
        {uniqueFilteredFields.map((field, index) => (
          <button
            key={index}
            onClick={() => onAddField(field)}
            className="cursor-pointer w-full px-6 py-3 border-b-[0.5px] border-[#11111140]"
          >
            <p className="flex w-full text-primary-black font-normal text-base capitalize">
              {field.name}
            </p>
          </button>
        ))}
        {uniqueFilteredFields.length === 0 && (
          <button className="flex items-center px-6 py-3">
            <FiPlus /> New Field
          </button>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default AddFieldModal
