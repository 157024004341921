import * as React from "react"
import { toolbarPlugin } from "@mdxeditor/editor"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { doc, updateDoc } from "firebase/firestore"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router"
import { useAnalytics } from "use-analytics"
import {
  Button,
  ConfirmationModal,
  Credenza as Dialog,
  CredenzaClose as DialogClose,
  CredenzaContent as DialogContent,
  CredenzaHeader as DialogHeader,
  CredenzaTitle as DialogTitle,
  CredenzaTrigger as DialogTrigger,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  RichEditor,
  ScrollArea,
  SimpleEditorToolbar,
  SubmitButton,
  toast,
} from "~/components/ui"
import { useAuth } from "~/context/AuthContext"
import { useBeforeunload } from "~/hooks/useBeforeunload"
import { useNoteTransform } from "~/hooks/useNotes"
import { Note, NoteStatus, TransformType } from "~/pages/Notes/types"
import { db } from "~/services/firebase"
import {
  formatTimestampAsNoteTitleDatePart,
  formatTimestampAsNoteTitleTimePart,
} from "~/utils/helperFunctions"

const formatText = (text: string) =>
  text.replace(/\*\*/g, "").replace(/\*/g, "-")

type EditTransformNoteModalProps = {
  note: Note
  transformType: TransformType
  isOpen?: boolean
  onOpenChange?: (isOpen: boolean) => void
}

export function EditTransformNoteModal({
  note,
  transformType,
  isOpen,
  onOpenChange,
  children,
}: React.PropsWithChildren<EditTransformNoteModalProps>) {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const formRef = React.useRef<HTMLFormElement | null>(null)
  const editorRef = React.useRef<React.ElementRef<typeof RichEditor> | null>(
    null
  )

  const transformQuery = useNoteTransform({
    noteId: note?.id,
    transformType: transformType,
  })

  const transform = transformQuery.data ?? null
  const transcription = transform?.text ? formatText(transform.text) : ""
  const form = useForm({
    values: {
      transcription,
    },
  })

  // MDXEditor is not a controlled component, so we need to set the markdown
  // by manually.
  React.useEffect(() => {
    if (transcription) {
      editorRef.current?.setMarkdown(transcription)
    }
  }, [transcription])

  /* Actions */
  const { track } = useAnalytics()
  const { currentUser } = useAuth()

  const updateTranscriptionDoc = async ({
    transcription,
  }: {
    transcription: string
  }): Promise<void> => {
    if (!transform) {
      throw new Error("Transform not available")
    }

    const updatedTranscription = transcription.trim()
    const noteRef = doc(db, `users/${currentUser?.uid}/notes/${note.id}`)

    await updateDoc(noteRef, {
      transformApplied: true,
      transformPromptId: transform?.promptId,
      editedTranscription: updatedTranscription,
      status: NoteStatus.Edited,
    })
  }

  const updateTranscription = useMutation({
    mutationFn: updateTranscriptionDoc,
    onSettled: () => {
      void track("Transform Applied", {
        promptId: transform?.promptId,
        version: transform?.version,
      })
    },
    onSuccess: () => {
      onOpenChange?.(false)
      void queryClient.invalidateQueries({ queryKey: ["NOTE"] })
      void navigate(`/notes/${note!.id}`)
    },
    onError: () => {
      toast.error(
        "Could not update your note. Please try again or contact support@joy.day."
      )
    },
  })

  const isDirty = !!form.formState.dirtyFields?.transcription
  const [isWarningModalOpen, setIsWarningModalOpen] = React.useState(false)

  useBeforeunload(() => {
    if (isDirty) {
      return "You have changes to a note that will be lost."
    }
  })

  const discardChanges = () => {
    form.reset({ transcription })
    // We need to do a more step here, reset the MDXEditor
    editorRef.current?.setMarkdown(transcription)
  }

  // Focus the editor when the modal is opened
  React.useEffect(() => {
    if (isOpen) {
      setTimeout(() => editorRef.current?.focus(), 100)
    }
  }, [isOpen])

  return (
    <>
      <Dialog
        shouldScaleBackground={false}
        open={isOpen}
        onOpenChange={(open) => {
          if (updateTranscription.isPending) return

          if (!open && isDirty) {
            setIsWarningModalOpen(true)
            return
          }

          onOpenChange?.(open)
        }}
      >
        <DialogTrigger asChild>{children}</DialogTrigger>
        <DialogContent
          closeable={false}
          indicator
          aria-describedby=""
          overlayClassName={"overflow-y-hidden p-0"}
          className={
            "flex flex-col md:rounded-none border-0 after:!content-none md:inset-x-auto md:bottom-auto md:w-full md:max-w-full h-[90%] md:h-full"
          }
          onKeyPress={(e) => {
            if (!e.target?.hasAttribute?.("contenteditable")) {
              editorRef.current?.focus()
            }
          }}
        >
          <DialogHeader className="sr-only">
            <DialogTitle>Edit Note</DialogTitle>
          </DialogHeader>

          <Form
            ref={formRef}
            form={form}
            data-vaul-no-drag=""
            className="flex-1 flex flex-col w-full lg:w-[860px] mx-auto p-4 md:p-0 min-h-0 max-h-[calc(100dvh-3rem)]"
            onSubmit={form.handleSubmit(async (values) => {
              await updateTranscription.mutateAsync({
                transcription: values.transcription,
              })

              form.reset()
            })}
          >
            <header className="shrink-0 grid grid-cols-2 md:flex items-center justify-between mb-2 sm:mb-4 gap-1 sm:gap-2">
              <DialogClose asChild>
                <Button
                  type="button"
                  variant="default"
                  data-invisible={!isDirty ? "true" : undefined}
                  className="justify-self-center rounded-full min-w-[100px]  min-h-[40px] sm:min-h-[49px] bg-transparent_gray text-black hover:bg-transparent_gray data-[invisible]:invisible"
                >
                  Cancel
                </Button>
              </DialogClose>

              <div className="order-[-1] md:order-none col-span-2 text-center">
                <h2 className="font-sans text-[17px] text-primary-black leading-snug font-bold">
                  {note?.clientId ? note?.title : "Note"}
                </h2>
                <p className="flex items-center justify-center gap-1 text-gray-500 font-sans text-[14px] md:text-[17px] leading-snug">
                  <span className="capitalize">
                    {formatTimestampAsNoteTitleDatePart(
                      note?.sessionStart ?? note.createdAt
                    )}
                  </span>
                  <span> · </span>
                  <span className="font-medium">
                    {formatTimestampAsNoteTitleTimePart(
                      note?.sessionStart ?? note.createdAt
                    )}
                  </span>
                </p>
              </div>

              <SubmitButton
                type="submit"
                variant="secondary"
                className="justify-self-center rounded-full min-w-[100px] min-h-[40px] sm:min-h-[49px]"
                isSubmitting={form.formState.isSubmitting}
                onClick={() => {
                  if (isDirty) {
                    formRef.current?.requestSubmit?.()
                  } else {
                    onOpenChange?.(false)
                  }
                }}
              >
                {isDirty ? "Save & Continue" : "Done"}
              </SubmitButton>
            </header>

            <ScrollArea className="flex-1 min-h-0 lg:w-[860px] border rounded-md p-2">
              <FormField
                control={form.control}
                name="transcription"
                rules={{
                  required: "Transcription is required",
                }}
                render={({ field }) => (
                  <FormItem className="flex flex-col flex-1 min-h-0">
                    <FormControl className="min-h-0">
                      <RichEditor
                        {...field}
                        ref={(ref) => {
                          field.ref(ref)
                          editorRef.current = ref
                        }}
                        onRequestSave={() => {
                          if (form.formState.isDirty) {
                            formRef.current?.requestSubmit?.()
                          }
                        }}
                        plugins={[
                          toolbarPlugin({
                            toolbarClassName:
                              "mdx-toolbar bg-light_gray/80 backdrop-blur rounded-md px-2.5 py-2 sticky top-auto bottom-0 sm:bottom-2 inset-x-0 mx-auto w-[112px] mb-4 overflow-hidden",
                            toolbarContents: SimpleEditorToolbar,
                          }),
                        ]}
                        placeholder={"Edit your note here"}
                        className="flex-1 flex flex-col-reverse w-full min-h-0"
                        contentEditableClassName="flex-1 font-sourceSerifPro leading-[31.16px] text-[17px] sm:text-[19px] min-h-0 p-0"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </ScrollArea>
          </Form>
        </DialogContent>
      </Dialog>

      <ConfirmationModal
        isOpen={isWarningModalOpen}
        onOpenChange={setIsWarningModalOpen}
        title="Unsaved changes"
        description="Unsaved changes will be lost. Are you sure you want to close?"
        closeButton="Discard Changes"
        confirmButton="Save"
        onConfirm={() => {
          formRef.current?.requestSubmit?.()
          setIsWarningModalOpen(false)
        }}
        onClose={() => {
          discardChanges()
          onOpenChange?.(false)
          setIsWarningModalOpen(false)
        }}
      />
    </>
  )
}
