import React, { useEffect, useRef, useState } from "react"
import { useMutation } from "@tanstack/react-query"
import { Crisp } from "crisp-sdk-web"
import { DateTime } from "luxon"
import { isMobile } from "react-device-detect"
import { Link } from "react-router"
import { useAnalytics } from "use-analytics"
import { FeedbackButtons } from "~/components/FeedbackButtons"
import { Button, RichEditor, toast } from "~/components/ui"
import { useLastNoteByClientId } from "~/hooks/firestore/useClients"
import { type Session } from "~/hooks/firestore/useSessions"
import { type Note } from "~/pages/Notes/types"
import { PROMPTS } from "~/utils/prompts"

export interface PrepSessionCardProps {
  session: Session
}

export function PrepSessionCard({ session }: PrepSessionCardProps) {
  const { track } = useAnalytics()
  const clientId = session.clientId || ""
  const [feedback, setFeedback] = useState<string>("")
  const [isFeedbackVisible, setIsFeedbackVisible] = useState(isMobile)
  const [isCardCollapsed, setIsCardCollapsed] = useState(false)

  const logFeedback = async ({ feedback }: { feedback: string }) => {
    // Add GA event
    if (feedback === "like") {
      void track("Home Preread_feedback_like")
    } else {
      void track("Home Preread_feedback_dislike")
    }
  }

  const handleTalkToSupport = () => {
    void track("Home Support_initiated_from_preread_feedback")
    try {
      if (!Crisp.chat.isVisible()) {
        Crisp.chat.show()
      }
      if (!Crisp.chat.isChatOpened()) {
        Crisp.chat.open()
      }
      Crisp.message.sendText(
        "Hello, I'd like to give some feedback on my pre-read."
      )
    } catch {
      toast.error("Failed to open chat. Please reach out at a later time.")
    } finally {
      toast.dismiss("pre-read-feedback")
    }
  }

  const feedbackMutation = useMutation({
    mutationFn: logFeedback,
    onMutate: async ({ feedback }) => {
      setFeedback(feedback)

      if (feedback === "dislike") {
        toast("We appreciate your feedback! 🙏", {
          id: "pre-read-feedback",
          description: "Let us know how we can improve: ",
          position: "bottom-left",
          dismissible: true,
          action: (
            <Button
              size="sm"
              className="px-2 py-1 h-auto text-xs font-normal rounded"
              variant="secondary"
              onClick={handleTalkToSupport}
            >
              Talk to Joy
            </Button>
          ),
          cancel: (
            <Button
              size="sm"
              className="px-2 py-1 h-auto text-xs font-normal rounded"
              variant="outline"
              onClick={() => {
                toast.dismiss("pre-read-feedback")
              }}
            >
              Not now..
            </Button>
          ),
          duration: Infinity,
        })
      }
    },
  })

  const lastNote = useLastNoteByClientId({
    clientId,
    reactQuery: {
      enabled: Boolean(clientId),
    },
  })

  const lastNoteData = (lastNote.data as Note) ?? {}

  const editorRef = useRef<React.ElementRef<typeof RichEditor> | null>(null)

  useEffect(() => {
    editorRef.current?.setMarkdown(session.preRead ?? "")
  }, [session.preRead, isCardCollapsed])

  if (isCardCollapsed) {
    return (
      <div
        tabIndex={0}
        className={`w-full sm:w-[412px] bg-white rounded-[1rem] overflow-hidden shadow-box-2 font-deco text-base ${isCardCollapsed ? "cursor-pointer" : "cursor-zoom-out"} mt-1 py-4 px-4 sm:px-6 transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2`}
        onClick={() => {
          setIsCardCollapsed(!isCardCollapsed)
          void track(`Home ${isCardCollapsed ? "Expand" : "Collapse"}_preread`)
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            setIsCardCollapsed(!isCardCollapsed)
          }
        }}
      >
        <div className="flex justify-between items-star">
          <h2 className="text-base font-bold tracking-tight text-gray-900 truncate">
            {session.title}
          </h2>
          <div className="flex self-center items-center text-sm text-muted-foreground">
            <span>
              {session.start.toLocaleString(DateTime.TIME_SIMPLE).toString()} -{" "}
              {session.start
                .plus(session.duration)
                .toLocaleString(DateTime.TIME_SIMPLE)
                .toString()}
            </span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      tabIndex={0}
      className={`w-full sm:w-[412px] bg-white rounded-[1rem] overflow-hidden shadow-box-2 font-deco text-base ${isCardCollapsed ? "cursor-pointer" : "cursor-zoom-out"} mt-1 px-4 sm:px-6 transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2`}
      onMouseEnter={() => {
        if (!isMobile) {
          setIsFeedbackVisible(true)
        }
      }}
      onMouseLeave={() => {
        if (!isMobile) {
          setIsFeedbackVisible(false)
        }
      }}
      onClick={() => {
        setIsCardCollapsed(!isCardCollapsed)
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          setIsCardCollapsed(!isCardCollapsed)
        }
      }}
    >
      <div className="flex justify-between items-start gap-1 md:gap-2 py-5">
        <h2 className="text-base font-bold tracking-tight text-gray-900 line-clamp-2">
          {session.title}
          {session.preRead && " pre-read"}
        </h2>
        <div className="flex self-center items-center text-sm text-muted-foreground">
          <span>
            {session.start.toLocaleString(DateTime.TIME_SIMPLE).toString()} -{" "}
            {session.start
              .plus(session.duration)
              .toLocaleString(DateTime.TIME_SIMPLE)
              .toString()}
          </span>
        </div>
      </div>

      <div>
        {session.personal ? (
          <p className="text-sm text-gray-500 mb-4">{session.description}</p>
        ) : (
          <>
            <RichEditor
              value={""}
              ref={(ref) => {
                editorRef.current = ref
              }}
              readOnly={true}
              placeholder={"Creating pre-read..."}
              className="text-sm text-gray-500 mb-2"
              contentEditableClassName="p-0"
            />
            <div className="flex justify-between items-center">
              {lastNoteData.id && (
                <Link
                  to={`/notes/${lastNoteData.id}`}
                  className="text-sm font-medium text-gray-500 hover:text-gray-900 transition-colors my-[-8px] underline underline-always"
                >
                  View client notes
                </Link>
              )}
              <FeedbackButtons
                onFeedback={(feedbackType) => {
                  feedbackMutation.mutate({ feedback: feedbackType })
                }}
                feedback={feedback}
                iconClassName={`w-6 h-6 ${isFeedbackVisible ? "block" : "hidden"}`}
                buttonClassName="w-8 h-8"
              />
            </div>
          </>
        )}
      </div>
      {!session.personal && (
        <p className="text-center text-sm text-gray-500 italic mt-3 mb-2">
          {PROMPTS.PREREAD_MISTAKES_INFO}
        </p>
      )}
    </div>
  )
}
