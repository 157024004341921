import UpgradeButton from "~/components/UpgradeButton"
import useSubscriptionHandler from "~/hooks/useSubscriptionHandler"
import { useLocation } from "react-router"
import {
  SubscriptionDataType,
  SubscriptionState,
} from "~/types/subscriptionTypes"

// The trial notice header is shown when the user is on a trial or unsubscribed
// and has less than the FREE_NOTES_THRESHOLD free notes remaining
const FREE_NOTES_THRESHOLD = 10

const REDIRECTING = "Redirecting..."
const ACTIVATE_NOW = "Activate now"

const NOTES_PATH = "/notes"

interface TrialNoticeHeaderProps {
  readonly classes: string
}

function shouldDisplayTrialNotice(
  pathname: string,
  isLoading: boolean,
  subscriptionData: SubscriptionDataType | undefined,
  freeNotes: number | undefined
): boolean {
  return (
    !isLoading && // Only show the trial notice if the subscription details have loaded
    pathname.endsWith(NOTES_PATH) && // Only show the trial notice on the notes page
    (subscriptionData?.state === SubscriptionState.TRIAL ||
      subscriptionData?.state === SubscriptionState.UNSUBSCRIBED) && // Only show amount of free notes remaining if the user is on a trial or unsubscribed
    freeNotes !== undefined &&
    freeNotes <= FREE_NOTES_THRESHOLD // Only show the trial notice if free notes are less than the threshold
  )
}

function getFreeNotesText(freeNotes: number | undefined): string {
  if (freeNotes === undefined) {
    return ""
  }

  if (freeNotes === 0) {
    return "No free notes remaining"
  } else if (freeNotes === 1) {
    return "1 free note remaining"
  } else {
    return `${freeNotes} free notes remaining`
  }
}

export default function TrialNoticeHeader({ classes }: TrialNoticeHeaderProps) {
  const location = useLocation()
  const {
    showSubscriptionInfo,
    isLoading,
    fetchingCheckoutSession,
    freeNotesRemaining,
    subscriptionData,
    upgradeSubscription,
  } = useSubscriptionHandler()

  const freeNotes = freeNotesRemaining()

  if (
    !showSubscriptionInfo() ||
    !shouldDisplayTrialNotice(
      location.pathname,
      isLoading,
      subscriptionData,
      freeNotes
    )
  ) {
    return null
  }

  const freeNotesText = getFreeNotesText(freeNotes)

  return (
    <div
      className={`flex flex-row items-center justify-center gap-3 ${classes}`}
    >
      <h2 className="text-md font-bold">{freeNotesText}</h2>
      <UpgradeButton
        size="sm"
        onClick={upgradeSubscription}
        disabled={fetchingCheckoutSession}
      >
        {fetchingCheckoutSession ? REDIRECTING : ACTIVATE_NOW}
      </UpgradeButton>
    </div>
  )
}
