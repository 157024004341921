import React, { useEffect, useRef, useState } from "react"
import { ChevronLeft, ChevronRight } from "lucide-react"
import { DateTime, Interval } from "luxon"
import Menu from "~/assets/icons/more-icon.svg?react"
import { Button } from "~/components/ui/button"
import { useBooking } from "~/context/BookingContext"
import {
  toWeekString,
  useSessions,
  type Session,
} from "~/hooks/firestore/useSessions"
import { is24HourClock } from "~/utils/time"
import BookedSlot from "./BookedSlot"
import SelectedSlot from "./SelectedSlot"
import Slot from "./Slot"

const SLOT_HEIGHT = 60

function formatHour(hour: number) {
  const date = new Date()
  date.setHours(hour, 0, 0, 0)

  if (is24HourClock) {
    // 24-hour format
    const hourFormatted = new Intl.DateTimeFormat(undefined, {
      hour: "numeric",
      hour12: false,
    }).format(date)
    return (
      <>
        {hourFormatted}
        <sup>00</sup>
      </>
    )
  } else {
    // 12-hour format
    return new Intl.DateTimeFormat(undefined, {
      hour: "numeric",
      hour12: true,
    }).format(date)
  }
}

function TimeDisplay({ hour }: { hour: number }) {
  return (
    <span
      className={`text-xs text-right font-['SF Pro'] lowercase ${is24HourClock && "pr-1"}`}
    >
      {formatHour(hour)}
    </span>
  )
}

interface WeekViewProps {
  onOpenSlotSelected: (date: DateTime) => void
  onBookingSelected: (slot: Session) => void
  onNavOpen: () => void
}

const WeekView: React.FC<WeekViewProps> = ({
  onOpenSlotSelected,
  onBookingSelected,
  onNavOpen,
}) => {
  const { currentSession } = useBooking()

  const [currentWeek, setCurrentWeek] = useState(
    DateTime.local().startOf("week")
  )
  const scrollWrapperRef = useRef<HTMLDivElement>(null)
  const headerRef = useRef<HTMLDivElement>(null)
  const [scrollbarWidth, setScrollbarWidth] = useState(0)

  const hours = Array.from({ length: 24 }, (_, i) => i)
  const days = Interval.fromDateTimes(
    currentWeek,
    currentWeek.plus({ days: 7 })
  )
    .splitBy({ days: 1 })
    .map((d) => d.start)
    .filter((day): day is DateTime => day !== null)

  const weekNumber = currentWeek.weekNumber

  const sessionsQuery = useSessions({ weekString: toWeekString(currentWeek) })
  const sessions = sessionsQuery.data

  // Sessions grouped by hour to optimize rendering
  const sessionsByHour = React.useMemo<Record<number, Session[]>>(() => {
    const preSessions = sessions ?? []

    // If currentSession is already in preSessions, replace it with the updated version
    const current = preSessions.find(
      (session) => session.id === currentSession?.id
    )

    if (current && currentSession) {
      preSessions.splice(preSessions.indexOf(current), 1)
      preSessions.push(currentSession)
    }

    // Group sessions by hour
    return preSessions.reduce((acc, session) => {
      const hourKey = Math.floor(session.start.toMillis() / 3600000)
      if (!acc[hourKey]) acc[hourKey] = []
      acc[hourKey].push(session)
      return acc
    }, {})
  }, [sessions, currentSession])

  const handlePreviousWeek = () => {
    setCurrentWeek((prevWeek) => prevWeek.minus({ weeks: 1 }))
  }

  const handleNextWeek = () => {
    setCurrentWeek((prevWeek) => prevWeek.plus({ weeks: 1 }))
  }

  const handleSelectBooking = (session: Session) => {
    onBookingSelected(session)
  }

  const handleSelectSlot = (date: DateTime | undefined) => {
    if (!date) {
      return
    }
    onOpenSlotSelected(date)
  }

  const handleToday = () => {
    setCurrentWeek(DateTime.local().startOf("week"))
  }

  const getSingleLetterDay = (day: DateTime) => {
    const shortWeekday = day.toLocaleString({ weekday: "short" })

    if (!shortWeekday) {
      // This should never happen
      return ""
    }

    // TODO: This is a hack to get the first letter of the day. Chinese, Japanese etc will not work
    return shortWeekday[0]
  }

  /**
   *  Format the day header
   *  - Show single letter day on mobile
   *  - Show short version day name on desktop
   * @param day
   * @returns Formatted day header component
   */
  const formatDayHeader = (day: DateTime) => {
    return (
      <div className="flex flex-col gap-0">
        <p className="sm:hidden capitalize">{getSingleLetterDay(day)}</p>
        <p className="hidden sm:inline capitalize">
          {day.toLocaleString({ weekday: "short" })}
        </p>
        <div className="flex flex-row gap-0 justify-center items-center">
          {isToday(day) && (
            <div className="hidden sm:block w-2 h-2 rounded-full bg-primary mr-1" />
          )}
          <p className={`text-xs sm:text-lg ${isToday(day) && "text-primary"}`}>
            {day.day}
          </p>
        </div>
      </div>
    )
  }

  const isToday = (day: DateTime | null) =>
    day?.hasSame(DateTime.local(), "day") ?? false

  useEffect(() => {
    const updateScrollbarWidth = () => {
      if (scrollWrapperRef.current) {
        const width =
          scrollWrapperRef.current.offsetWidth -
          scrollWrapperRef.current.clientWidth
        setScrollbarWidth(width)
      }
    }

    updateScrollbarWidth()
    window.addEventListener("resize", updateScrollbarWidth)

    // Auto-scroll to 8am
    if (scrollWrapperRef.current) {
      const eightAMOffset = 8 * SLOT_HEIGHT // 8 hours * SLOT_HEIGHT pixels per hour
      scrollWrapperRef.current.scrollTop = eightAMOffset
    }

    return () => window.removeEventListener("resize", updateScrollbarWidth)
  }, [])

  // Add interval update component every minute
  useEffect(() => {
    const interval = setInterval(() => {
      // Force rerender
      setCurrentWeek(DateTime.local().startOf("week"))
    }, 60000)
    return () => clearInterval(interval)
  }, [])

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-between items-center md:mb-1 py-1 px-1 md:px-2">
        <Button
          onClick={(e) => {
            e.preventDefault()
            onNavOpen()
          }}
          size="icon"
          variant="ghost"
        >
          <Menu />
        </Button>

        <h2 className="text-sm sm:text-xl font-bold capitalize">
          {currentWeek.plus({ days: 6 }).toLocaleString({
            month: "long",
            year: "numeric",
          })}
        </h2>
        <div className="flex items-center gap-1 md:gap-2">
          <Button
            onClick={handleToday}
            variant="outline"
            size="sm"
          >
            Today
          </Button>
          <Button
            onClick={handlePreviousWeek}
            variant="outline"
            size="sm"
          >
            <ChevronLeft className="h-4 w-4" />
          </Button>
          <Button
            onClick={handleNextWeek}
            variant="outline"
            size="sm"
          >
            <ChevronRight className="h-4 w-4" />
          </Button>
        </div>
      </div>
      <div
        ref={headerRef}
        className="grid gap-1 sticky top-0 z-10 bg-background"
        style={{
          gridTemplateColumns: "0.3fr repeat(7, 1fr)",
          paddingRight: `${scrollbarWidth}px`,
        }}
      >
        <div className="col-span-1 flex items-center justify-center p-1">
          <div className="text-xs font-medium bg-blue-100 text-blue-800  rounded-sm px-1 py-1">
            {weekNumber}
          </div>
        </div>
        {days.map((day) => (
          <div
            key={day?.toISO()}
            className="text-center font-medium p-2"
          >
            <div
              className={`flex items-center justify-center ${isToday(day) ? "text-primary border-2 border-primary sm:border-none" : ""}`}
            >
              {formatDayHeader(day)}
            </div>
          </div>
        ))}
      </div>
      <div
        ref={scrollWrapperRef}
        className="flex-grow overflow-y-auto relative"
      >
        <div
          className="grid gap-[1px] md:gap-[1px] bg-slate-200"
          style={{
            height: `${24 * SLOT_HEIGHT}px`,
            gridTemplateColumns: "0.3fr repeat(7, 1fr)",
            gridTemplateRows: `repeat(24, ${SLOT_HEIGHT}px)`,
          }}
        >
          {hours.map((hour) => (
            <React.Fragment key={hour}>
              <div
                className={`height-[${SLOT_HEIGHT}px] flex items-start justify-end bg-background my-[-6px]`}
              >
                <p className="text-xs text-right pr-1 font-['SF Pro']">
                  {hour !== 0 && TimeDisplay({ hour })}
                </p>
              </div>
              {days.map((day) => {
                const isWorkingHour = hour >= 8 && hour < 18

                const slotDate = day?.set({ hour })

                // Calculate the slot hour to check for overlaps and selected slot
                const slotHour = Math.floor(slotDate.toMillis() / 3600000)

                let isSelected =
                  currentSession &&
                  Math.floor(currentSession.start.toMillis() / 3600000) ===
                    Math.floor(slotDate.toMillis() / 3600000)

                // Check if the current slot overlaps with any pre-booked session
                const overlappingSessions = sessionsByHour[slotHour] || []

                // Check if the current session is part of overlapping sessions for this hour
                if (isSelected) {
                  isSelected = !overlappingSessions.find(
                    (session) => session.id === currentSession?.id
                  )
                }

                // Check if the current slot is the current hour
                // and calculate the offset based on the current minute
                const now = DateTime.local()
                const currentHour = Math.floor(now.toMillis() / 3600000)
                const isCurrentHour = currentHour === slotHour
                const minutesOffset = Math.floor(
                  (now.minute / 60) * SLOT_HEIGHT
                )

                return (
                  <div
                    key={slotDate?.toISO()}
                    className={`relative h-[${SLOT_HEIGHT}px`}
                  >
                    <Slot
                      key={slotDate?.toISO() + "slot"}
                      slotHeight={SLOT_HEIGHT}
                      onClick={() => handleSelectSlot(slotDate)}
                      isWorkingHour={isWorkingHour}
                    />
                    {overlappingSessions?.map((session) => {
                      return (
                        <BookedSlot
                          key={session.id}
                          session={
                            session.id === currentSession?.id
                              ? currentSession
                              : session
                          } // Show the current session instead of stored to keep it updated to local changes
                          onClick={(session) => {
                            handleSelectBooking(session)
                          }}
                          slotHeight={SLOT_HEIGHT}
                          isSelected={currentSession?.id === session.id}
                        />
                      )
                    })}
                    {isSelected && currentSession && (
                      <SelectedSlot
                        key={"selected"}
                        booking={currentSession}
                        slotHeight={SLOT_HEIGHT}
                      />
                    )}
                    {isCurrentHour && (
                      <div
                        className="absolute top-0 left-0 right-0 h-0.5 bg-primary flex items-center justify-start z-10"
                        style={{
                          transform: `translateY(${minutesOffset}px)`,
                        }}
                      >
                        <div className="w-2 h-2 rounded-full bg-primary mx-[-4px]" />
                      </div>
                    )}
                  </div>
                )
              })}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

export default WeekView
