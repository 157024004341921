import * as React from "react"
import { useQueryClient } from "@tanstack/react-query"
import { AnimatePresence, motion } from "framer-motion"
import { Undo2 } from "lucide-react"
import { useForm } from "react-hook-form"
import AudioPlayer from "~/components/AudioPlayer"
import NoteActions from "~/components/NoteActions"
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  RichEditor,
} from "~/components/ui"
import { useTranscribedNote, useUndoDeleteNote } from "~/hooks/useNotes"
import { EditNoteModal, parseTranscribed } from "~/pages/Notes/EditNoteModal"
import {
  formatTimestampAsNoteTitleDatePart,
  formatTimestampAsNoteTitleTimePart,
} from "~/utils/helperFunctions"
import { NoteStatus, type Note } from "./types"

export type NoteItemProps = {
  note: Note
  initialExpanded?: boolean
  initialOpenEdit?: boolean
  onDeletedNote?: (noteId: string) => void
  onClientRemoved?: (noteId: string) => void
}

export function NoteItem({
  note,
  onDeletedNote,
  onClientRemoved,
  initialExpanded = false,
  initialOpenEdit = false,
}: NoteItemProps) {
  const queryClient = useQueryClient()

  const [isExpanded, setIsExpanded] = React.useState(initialExpanded)
  const [showRecording, setShowRecording] = React.useState(false)
  const [isOpen, setIsOpen] = React.useState(initialOpenEdit)

  /* Actions */
  const undoDeleteNote = useUndoDeleteNote({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["NOTE", note.id],
      })
    },
  })

  const isImprovementRequested = note.status === NoteStatus.ImprovementRequested
  const transcribed = useTranscribedNote({
    note,
    reactQuery: {
      enabled: isImprovementRequested,
    },
  })

  const formRef = React.useRef<HTMLFormElement | null>(null)
  const transcription = isImprovementRequested
    ? (transcribed.data ?? "")
    : parseTranscribed(note)

  const form = useForm({
    values: {
      transcription,
    },
  })

  const contentRef = React.useRef<HTMLDivElement | null>(null)
  const editorRef = React.useRef<React.ElementRef<typeof RichEditor> | null>(
    null
  )

  // MDXEditor is not a controlled component, so we need to set the markdown
  // by manually.
  React.useEffect(() => {
    if (transcription) {
      editorRef.current?.setMarkdown(transcription)
    }
  }, [transcription])

  return (
    <div
      key={note.id}
      ref={contentRef}
      className={`notes-detail-bg mb-2 p-6 relative ${
        !isExpanded ? "max-h-30 overflow-hidden" : ""
      }`}
      role={isExpanded ? undefined : "button"}
      tabIndex={isExpanded ? 0 : undefined}
      data-expanded={isExpanded ? "true" : undefined}
      onClick={() => {
        if (!isExpanded) {
          setIsExpanded(true)
        }
      }}
    >
      {note.deletedAt ? (
        <div className="flex bg-medium-grey-350 justify-between font-archivo my-3 px-6 py-3 rounded-2xl bg-zinc-100/80 text-primary-black items-center">
          <span>Deleted</span>
          <Button
            type="button"
            variant="outline"
            className="bg-gray-100 border-0 px-5 py-3.5 rounded-lg text-base h-[52px]"
            isLoading={undoDeleteNote.isPending}
            onClick={() => undoDeleteNote.mutate({ noteId: note.id })}
          >
            <Undo2 className="size-4" />
            Undo
          </Button>
        </div>
      ) : (
        <Form
          ref={formRef}
          form={form}
        >
          <div
            tabIndex={0}
            role="button"
            className="w-fit mb-4"
            onClick={() => {
              if (isExpanded) {
                setIsExpanded(false)
              }
            }}
          >
            <p>
              <span className="text-primary-black font-mono tracking-wide text-lg font-bold capitalize">
                {formatTimestampAsNoteTitleDatePart(
                  note?.sessionStart ?? note.createdAt
                )}
              </span>
              <span> · </span>
              <span className="text-gray-500 font-mono tracking-wide text-lg font-medium">
                {formatTimestampAsNoteTitleTimePart(
                  note?.sessionStart ?? note.createdAt
                )}
              </span>
            </p>
          </div>

          {isExpanded && (
            <div className="relative h-12 -mx-6 overflow-hidden">
              <AnimatePresence>
                <motion.div
                  key="edit-button"
                  initial={{
                    x: "100%",
                    opacity: 0,
                  }} // Start from right off-screen
                  animate={{ x: "0%", opacity: 1 }} // Slide in to view
                  exit={{
                    x: "100%",
                    opacity: 0,
                  }} // Slide out to right off-screen
                  transition={{ duration: 0.3, ease: "easeInOut" }}
                  className="absolute right-0"
                >
                  <EditNoteModal
                    note={note}
                    isOpen={isOpen}
                    onOpenChange={setIsOpen}
                  >
                    <Button
                      type="button"
                      size="sm"
                      variant="secondary"
                      className="rounded-tl-md rounded-bl-md rounded-tr-none rounded-br-none min-w-[70px]"
                    >
                      Edit
                    </Button>
                  </EditNoteModal>
                </motion.div>
              </AnimatePresence>
            </div>
          )}

          <FormField
            control={form.control}
            name="transcription"
            rules={{
              required: "Transcription is required",
            }}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <RichEditor
                    {...field}
                    ref={(ref) => {
                      field.ref(ref)
                      editorRef.current = ref
                    }}
                    readOnly={true}
                    placeholder={"Edit your note here"}
                    onRequestSave={() => {
                      if (form.formState.isDirty) {
                        formRef.current?.requestSubmit?.()
                      }
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {showRecording && (
            <AudioPlayer
              storageId={note?.storageRef as string}
              onClose={setShowRecording}
            />
          )}

          <div className="flex flex-col items-start mt-5">
            <NoteActions
              note={note}
              setShowRecording={setShowRecording}
              onDeletedNote={onDeletedNote}
              onClientRemoved={onClientRemoved}
            />
          </div>
        </Form>
      )}

      {!isExpanded ? (
        <div className="absolute bottom-0 left-0 right-0 h-16 bg-gradient-to-t from-white to-transparent pointer-events-none" />
      ) : null}
    </div>
  )
}
