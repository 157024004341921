import { Timestamp } from "firebase/firestore"
import { DateTime } from "luxon"
import { Note } from "~/pages/Notes/types"
import { UserStatisticsType } from "~/types/userTypes"
import { BAR_HEIGHT_MAX, BAR_HEIGHT_MIN } from "./const-strings"

/**
 * Reduces an array of volume data to presentable data
 * @param {number[]} data Frequency data array
 * @param {number} targetLength Target length of the reduced array
 * @return {number[]} Reduced array of numbers
 */
export const createArrayOfAverageVolume = (
  data: number[],
  targetLength = 25
): number[] => {
  const result: number[] = []

  // Calulate the size of each group, use half of the input data length
  const groupSize = Math.ceil(data.length / 2 / targetLength)
  const skipInitialBars = 5

  // Loop through the data and calculate the average of each group
  for (let i = skipInitialBars; i < targetLength + skipInitialBars; i++) {
    const start = i * groupSize
    const end = start + groupSize
    const group = data.slice(start, end)

    const average =
      group.length > 0
        ? group.reduce((sum, value) => sum + value, 0) / group.length
        : 0

    result.push(average)
  }
  return result
}

export const calculateBarHeight = (frequency: number) => {
  return `${Math.max((frequency * BAR_HEIGHT_MAX) / 100, BAR_HEIGHT_MIN)}px`
}

export const isToday = (dt: DateTime): boolean =>
  dt.hasSame(DateTime.now(), "day")

export const isThisWeek = (dt: DateTime): boolean =>
  dt.hasSame(DateTime.now(), "week")

export const isThisYear = (dt: DateTime): boolean =>
  dt.hasSame(DateTime.now(), "year")

export const formatTimestampAsNoteTitleTimePart = (
  timestamp: Timestamp
): string => {
  return DateTime.fromMillis(timestamp.toMillis()).toLocaleString(
    DateTime.TIME_SIMPLE
  )
}

export const formatTimestampAsNoteTitleDatePart = (
  timestamp: Timestamp
): string => {
  const dt = DateTime.fromMillis(timestamp.toMillis())
  if (isToday(dt)) {
    return "Today"
  } else if (isThisWeek(dt)) {
    return dt.toLocaleString({ weekday: "long" })
  } else if (isThisYear(dt)) {
    return dt.toLocaleString({
      month: "short",
      day: "2-digit",
      weekday: "short",
    })
  } else {
    return dt.toLocaleString({
      year: "numeric",
      month: "short",
      day: "2-digit",
      weekday: "short",
    })
  }
}

export const formatTimestampAsNoteTitle = (timestamp: Timestamp): string => {
  const dt = DateTime.fromMillis(timestamp.toMillis())
  if (isToday(dt)) {
    return `Today · ${dt.toLocaleString(DateTime.TIME_SIMPLE)}`
  } else if (isThisWeek(dt)) {
    return dt.toLocaleString({
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    })
  } else if (isThisYear(dt)) {
    return dt.toLocaleString({
      month: "short",
      day: "2-digit",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
    })
  } else {
    return dt.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)
  }
}

export const formatTimestampAsNoteTitleShort = (
  timestamp: Timestamp
): string => {
  const dt = DateTime.fromMillis(timestamp.toMillis())
  if (isToday(dt)) {
    return "Today"
  } else if (isThisWeek(dt)) {
    return dt.toLocaleString({
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
    })
  } else if (isThisYear(dt)) {
    return dt.toLocaleString({
      month: "short",
      day: "2-digit",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
    })
  } else {
    return dt.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
  }
}

export const getNoteDateTitle = (
  userStatistics: UserStatisticsType | null,
  note: Note
): string => {
  if (userStatistics && userStatistics.totalSavedNotes === 0) {
    return "My first note"
  }
  return DateTime.fromMillis(note.createdAt.toMillis()).toLocaleString(
    DateTime.DATETIME_MED_WITH_WEEKDAY
  )
}

export function getDefaultTitle(userStatistics?: UserStatisticsType) {
  if (userStatistics?.totalSavedNotes === 0) {
    return "My first note"
  }
  const dateTimeString = DateTime.now().toLocaleString(
    DateTime.DATETIME_MED_WITH_WEEKDAY
  )
  // Capitalize first letter
  const capitalizedDateTimeString =
    dateTimeString.charAt(0).toUpperCase() + dateTimeString.slice(1)

  return capitalizedDateTimeString
}

export function getDefaultComment(userStatistics?: UserStatisticsType) {
  return userStatistics?.totalSavedNotes === 0
    ? "I think I did a good job!"
    : ""
}

export function formatAudioTime(seconds: number) {
  const minutes = Math.floor(seconds / 60)
  const secs = Math.floor(seconds % 60)
  return `${minutes}:${secs < 10 ? "0" : ""}${secs}`
}
