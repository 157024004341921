import * as React from "react"
import { QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { useLocation } from "react-router"
import { Toaster, TooltipProvider } from "~/components/ui"
import { UpdateNotifier } from "~/components/UpdateNotifier"
import PreReadCreator from "./components/PreReadCreator"
import { queryClient } from "./lib/react-query"

const UPDATE_NOTIFY_WHITELISTED_ROUTES = ["/notes", "/home"]

interface ProvidersProps {
  children?: React.ReactNode
}

export function Providers({ children }: ProvidersProps) {
  const location = useLocation()

  return (
    <QueryClientProvider client={queryClient}>
      <TooltipProvider
        delayDuration={0}
        skipDelayDuration={0}
      >
        {children}
        <Toaster />
        <PreReadCreator />

        <ReactQueryDevtools buttonPosition={"bottom-right"} />
        {UPDATE_NOTIFY_WHITELISTED_ROUTES.includes(location.pathname) ? (
          <UpdateNotifier />
        ) : null}
      </TooltipProvider>
    </QueryClientProvider>
  )
}
