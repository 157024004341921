import React, { useEffect } from "react"
import { captureException } from "@sentry/browser"
import { useQueryClient } from "@tanstack/react-query"
import { Crisp } from "crisp-sdk-web"
import { useAnalytics } from "use-analytics"
import { Button, toast } from "~/components/ui"
import {
  Credenza,
  CredenzaBody,
  CredenzaContent,
  CredenzaFooter,
  CredenzaHeader,
  CredenzaTitle,
} from "~/components/ui/credenza"
import { useDeleteNote } from "~/hooks/useNotes"
import { downloadRecording } from "~/utils/downloadRecordings"
import { LocalRecordings } from "~/utils/recordings/local"
import RecordingRetryUploadDialog, {
  RecordingRetryUploadState,
} from "./RecordingRetryUploadDialog"
import { Note, NoteStatus } from "./types"

interface NoteBeingProcessedDialogProps {
  note: Note | undefined
  setNoteBeingProcessed: (note: Note | undefined) => void
}

interface NoteProcessingActionsProps {
  note: Note | undefined
  isDownloading: boolean
  handleDownloadRecording: (note: Note) => Promise<void>
}

const NoteProcessingActions: React.FC<NoteProcessingActionsProps> = ({
  note,
  handleDownloadRecording,
  isDownloading,
}) => {
  if (!note) {
    return null
  }
  return (
    <Button
      onClick={(event) => {
        event.preventDefault()
        void handleDownloadRecording(note)
      }}
      size="default"
      variant="secondary"
      disabled={isDownloading}
    >
      {isDownloading ? "Downloading..." : "Download recording"}
    </Button>
  )
}

interface NoteUploadingActionsProps {
  note: Note | undefined
  isLocalFileAvailable: boolean
  isRetryingUpload: boolean
  handleRetryUpload: () => void
  isDownloading: boolean
  handleDownloadRecording: (note: Note) => Promise<void>
}

const NoteUploadingActions: React.FC<NoteUploadingActionsProps> = ({
  note,
  isLocalFileAvailable,
  isRetryingUpload,
  handleRetryUpload,
  isDownloading,
  handleDownloadRecording,
}) => {
  if (!note) {
    return null
  }

  return (
    <>
      {isLocalFileAvailable && (
        <Button
          onClick={(event) => {
            event.preventDefault()
            handleRetryUpload()
          }}
          size="default"
          variant="secondary"
          disabled={isRetryingUpload}
        >
          {isRetryingUpload ? "Retrying..." : "Retry upload"}
        </Button>
      )}

      <Button
        onClick={(event) => {
          event.preventDefault()
          void handleDownloadRecording(note)
        }}
        size="default"
        variant="secondary"
        disabled={isDownloading}
      >
        {isDownloading ? "Downloading..." : "Download recording"}
      </Button>
    </>
  )
}

const NoteBeingProcessedDialog: React.FC<NoteBeingProcessedDialogProps> = ({
  note,
  setNoteBeingProcessed,
}) => {
  const queryClient = useQueryClient()
  const { track } = useAnalytics()
  const [isDownloading, setIsDownloading] = React.useState(false)
  const [localFileAvailable, setLocalFileAvailable] = React.useState(false)
  const [retryUpload, setRetryUpload] = React.useState(false)

  useEffect(() => {
    if (note?.id) {
      const local = new LocalRecordings()
      local
        .status(note.id)
        .then(async (status) => {
          if (status?.state !== "deleted") {
            setLocalFileAvailable(true)
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [note?.id])

  const invalidateNote = React.useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey: ["NOTE", note?.id],
      }),
    [queryClient, note]
  )

  const deleteNote = useDeleteNote({
    onSettled: async (_, error) => {
      if (error) {
        toast.error("Error deleting note, please try again!")
      } else {
        if (
          note?.status === NoteStatus.Uploading ||
          note?.status === NoteStatus.Recording
        ) {
          void track("Note Deleted_while_uploading_from_processing_dialog")
        } else {
          void track("Note Deleted_while_processing_from_processing_dialog")
        }
        await invalidateNote()
        setNoteBeingProcessed(undefined)
      }
    },
  })

  const handleDownloadRecording = async (note: Note) => {
    setIsDownloading(true)
    void track("Note Recording_downloaded_from_processing_dialog")
    try {
      await downloadRecording(note)
    } catch (error) {
      captureException(error)
      toast.error("Could not download the recording, contact support")
      void track("Note Error_downloading_recording_from_processing_dialog")
    } finally {
      setIsDownloading(false)
    }
  }

  const handleTalkToSupport = () => {
    void track("Note Support_initiated_from_processing_dialog")
    if (!Crisp.chat.isChatOpened()) {
      Crisp.chat.open()
    }
    Crisp.message.sendText("Hello, I have a question about note: " + note?.id)
  }

  const handleRetryUpload = () => {
    setRetryUpload(true)
    void track("Note Retry_upload_initiated_from_processing_dialog")
  }

  return (
    <>
      {retryUpload && (
        <RecordingRetryUploadDialog
          recordingId={note?.id} // Note id equals recording id
          onDismissed={(status) => {
            // If the upload was successful, close both the retry and parent dialog
            setRetryUpload(false)
            if (status === RecordingRetryUploadState.SUCCESS) {
              setNoteBeingProcessed(undefined)
            }
          }}
        />
      )}

      <Credenza
        open={note !== undefined}
        onOpenChange={() => {
          setNoteBeingProcessed(undefined)
        }}
      >
        <CredenzaContent>
          <CredenzaHeader className="space-y-0.5">
            <CredenzaTitle>
              {note?.status === NoteStatus.Recording ||
              note?.status === NoteStatus.Uploading
                ? "Your note is uploading"
                : "Your note is being transcribed"}
            </CredenzaTitle>
          </CredenzaHeader>

          <CredenzaBody
            data-vaul-no-drag=""
            className="overflow-y-auto max-h-96 md:max-h-[27rem] px-4"
          >
            <div className="flex flex-col justify-center">
              <p className="mb-2">Please wait for it to complete.</p>
              <p className="mb-4">
                If you think there was an error, talk to us{" "}
                {localFileAvailable && ", retry the upload"} or delete the note.
                You can also download the recording to store it on your device.
              </p>
              <div className="self-start flex flex-col gap-2">
                <Button
                  onClick={handleTalkToSupport}
                  size="default"
                  variant="secondary"
                >
                  Talk to support
                </Button>

                {(note?.status === NoteStatus.Recording ||
                  note?.status === NoteStatus.Uploading) && (
                  <NoteUploadingActions
                    note={note}
                    isLocalFileAvailable={localFileAvailable}
                    isRetryingUpload={retryUpload}
                    handleRetryUpload={handleRetryUpload}
                    isDownloading={isDownloading}
                    handleDownloadRecording={handleDownloadRecording}
                  />
                )}

                {note?.status === NoteStatus.Processing && (
                  <NoteProcessingActions
                    note={note}
                    isDownloading={isDownloading}
                    handleDownloadRecording={handleDownloadRecording}
                  />
                )}

                <Button
                  onClick={() => deleteNote.mutate({ noteId: note?.id ?? "" })}
                  size="default"
                  variant="secondary"
                >
                  Delete this note
                </Button>
              </div>
            </div>
          </CredenzaBody>

          <CredenzaFooter className="">
            <Button
              variant="default"
              onClick={() => {
                setNoteBeingProcessed(undefined)
              }}
            >
              Okay
            </Button>
          </CredenzaFooter>
        </CredenzaContent>
      </Credenza>
    </>
  )
}

export default NoteBeingProcessedDialog
