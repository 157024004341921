import React from "react"
import { useNavigate } from "react-router"
import { Button } from "~/components/ui"

export default function NotFound() {
  const navigate = useNavigate()

  return (
    <div className="w-full h-full flex flex-col justify-center items-center gap-2">
      <h1 className="text-3xl font-bold">404 - Not Found</h1>

      <p className="text-sm text-muted-foreground mb-4">
        The page you are looking for does not exist.
      </p>

      <Button onClick={() => navigate(-1)}>
        Take me back to where I came from
      </Button>
    </div>
  )
}
