import * as React from "react"
import fastq from "fastq"

export function useQueue<T = unknown>({
  worker,
  concurrency = 1,
  name = "[useQueue]",
}: {
  worker: (args: T) => Promise<void>
  concurrency?: number
  name?: string
}) {
  const q = React.useRef<fastq.queueAsPromised<T>>(
    fastq.promise(worker, concurrency)
  )

  React.useEffect(() => {
    const _q = q.current
    _q.error((err) => {
      if (err) console.error(`${name} error`, err)
    })

    return () => {
      _q.kill()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    push: q.current.push,
  }
}
