import React, { useState } from "react"
import { useNavigate, useParams } from "react-router"
import { useAnalytics } from "use-analytics"
import ClientDetailsModal from "~/components/ClientDetailsModal"
import ClientModal from "~/components/ClientModal"
import EditClientModal from "~/components/EditClientModal"
import { Button } from "~/components/ui"
import { useAuth } from "~/context/AuthContext"
import { useGetNoteById, useNoteTransforms } from "~/hooks/useNotes"
import { clientFields } from "~/utils/noteUtils"
import NoteHeader from "./NoteHeader"
import NoteTransformButtons from "./NoteTransformButtons"
import NoteTransformContent from "./NoteTransformContent"
import { TransformType } from "./types"

interface Field {
  name: string
  type: string
  value: string
}

export default function NoteTransformSelection() {
  const navigate = useNavigate()
  const { noteId } = useParams()
  const { currentUser } = useAuth()
  const { track } = useAnalytics()

  const [selectedButton, setSelectedButton] = useState<TransformType>(
    TransformType.Default
  )
  const [availableFields, setAvailableFields] = useState<Field[]>(clientFields)
  const [openModal, setOpenModal] = useState(false)
  const [openClientDetailsModal, setOpenClientDetailsModal] = useState(false)
  const [editClientModal, setEditClientModal] = useState(false)

  const noteInfo = useGetNoteById({ noteId: noteId! })
  const noteData = noteInfo.data

  // Redirect to notes page if note does not exist
  React.useEffect(() => {
    if (noteInfo.error) {
      void navigate("/notes")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteInfo.error])

  const transformsQuery = useNoteTransforms({ noteId: noteId! })
  const transforms = transformsQuery.data ?? []

  const handleTransformClick = (transformType: TransformType) => {
    if (!currentUser?.uid || !noteId) return

    void track(`Transform Navigate_from_selection_to_${transformType}_preview`)

    void navigate(`/notes/${noteId}/template-preview`, {
      state: { transformType },
    })
  }

  const handleKeepUntouched = () => {
    if (!currentUser?.uid || !noteId) return

    void track("Transform Keep_untouched")

    void navigate(`/notes/${noteId}`)
  }

  return (
    <div className="bg-white h-dvh text-normal-black-400 flex flex-col">
      <NoteHeader
        noteData={noteData}
        setOpenClientDetailsModal={setOpenClientDetailsModal}
        setOpenModal={setOpenModal}
      />
      <div className="flex flex-col h-dvh overflow-auto pb-20 md:pb-4">
        <h1 className="font-platypi text-2xl leading-normal font-medium self-center py-8 mb-6">
          Apply a smart format
        </h1>

        <div className="flex flex-col flex-1 justify-top">
          <NoteTransformButtons
            selectedButton={selectedButton}
            setSelectedButton={setSelectedButton}
          />

          <NoteTransformContent
            selectedButton={selectedButton}
            handleTransformClick={handleTransformClick}
            transforms={transforms}
            note={noteData}
          />

          <Button
            size={"sm"}
            variant="link"
            className="py-2 px-4 -mt-2 mb-4 text-sm text-black hover:text-primary"
            onClick={handleKeepUntouched}
          >
            Leave text untouched
          </Button>
        </div>
      </div>

      <ClientModal
        noteId={noteData?.id || ""}
        isOpen={openModal}
        onOpenChange={setOpenModal}
      />

      {noteData && (
        <>
          <ClientDetailsModal
            isOpen={openClientDetailsModal}
            setOpenClientDetailsModal={setOpenClientDetailsModal}
            clientId={noteData?.clientId || ""}
            editButtonHandler={() => setEditClientModal(true)}
          />

          {noteData.clientId && noteId && (
            <EditClientModal
              isOpen={editClientModal}
              setOpenModal={setEditClientModal}
              clientId={noteData.clientId}
              onSuccess={async () => {
                await noteInfo?.refetch()
              }}
              addedFields={[]}
              setAvailableFields={setAvailableFields}
              availableFields={availableFields}
              onCloseAllModals={() => {
                setOpenModal(false)
                setOpenClientDetailsModal(false)
                setEditClientModal(false)
              }}
            />
          )}
        </>
      )}
    </div>
  )
}
