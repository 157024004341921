import { Timestamp } from "firebase/firestore"

export interface RecordingStatus {
  recordingId: string
  storagePath: string
  bytesUploaded: number
  // when streaming we don't know how large the file will be
  totalBytes?: number
}

export interface Note {
  id: string
  comment: string
  copied: boolean
  createdAt: Timestamp
  duration: number
  editedTranscription: string
  improvedTranscription: string
  improvementRequestRef: string
  language: string
  serverTimestamp: Timestamp
  deletedAt: Timestamp
  status: NoteStatus
  storageRef: string
  recording?: RecordingStatus
  tags: []
  title: string
  transcription: string
  clientId: string
  clientName: string
  sessionId?: string
  sessionStart?: Timestamp
  viewed: boolean
  viewedWithUndo: boolean
  errorMessage: string
}

export interface Improvement {
  createdAt: Timestamp
  improvedTranscription: string
}

export enum NoteStatus {
  Recording = "recording",
  Uploading = "uploading",
  Processing = "processing",
  Error = "error",
  Transcribed = "transcribed",
  Edited = "edited",
  ImprovementRequested = "improvement_requested",
  Improved = "improved",
}

export interface Field {
  name: string
  type: string
  value: string
}

export enum TransformType {
  Default = "default",
  SOAP = "soap",
  MyOwn = "myown",
}

export interface Transform {
  id: string
  text: string
  status: string
  promptId: string
  version: string
  metadata: {
    elapsedTimeMs: number
    model: string
    requestTokens: number
    responseTokens: number
  }
}
