import {
  UNKNOWN_MIME_TYPE,
  UNKNOWN_MIME_TYPE_FILE_NAME,
} from "~/utils/const-strings"

export interface PersistentRecording {
  recordingId: string

  // Flag indicating if this is performing background operations
  usesBackgroundOperations: boolean

  // A path where the recording can be accessed
  accessPath: string

  // Write or append the data to the current object
  write(data: Blob): Promise<void>

  // Status of the recording persistence
  status(): Promise<RecordingSyncStatus>

  // Close the recording, indicating that there wont be any more writes.
  // Waits for all writes to finish OR when handed over to a background task.
  close(): Promise<void>

  // Delete the file
  delete(): Promise<void>
}

export class PersistentRecordingError extends Error {
  constructor(reason?: string) {
    super(reason)
  }
}

//// PROTOCOL

export type SyncState =
  | "init" // starting state for all recordings
  | "streaming" // the recording is actively streaming data
  | "stored" // the data is fully persisted to local storage
  | "syncing" // syncing local with remote, overwritten by uploading
  | "syncing-error-retry" // failed syncing, fine to retry
  | "synced" // the remote storage has completed the sync
  | "error" // The recording failed to sync or write
  | "deleted" // The recording has been deleted locally

// TODO: We transitioned from recordings without chunks to chunked recordings
// The non-chunked recording is kept for backwards compatibility and can be
// removed when we are confident everyone has upgraded.
export type RecordingSyncKind =
  | "recording" // Recording without chunks
  | "recording-chunked" // A recording split into chunks
  | "chunk" // A single chunk of the recording

export interface RecordingSyncStatus {
  kind: RecordingSyncKind
  recordingId: string
  chunkId?: number
  totalChunks?: number
  bytesWritten: number
  bytesSynced: number
  // When streaming, the total bytes is not yet known
  totalBytes?: number
  remotePath?: string // updated when the remote object is created
  state: SyncState
  errorMessage?: string
  createdAt: Date // Recording creation time
  updatedAt: Date // State update time
  syncAttempts?: number
  lastWriteAt?: Date // Last completed write including remote sync operations
  contentType?: string
}

export interface RecordingMessage {
  kind: string
}

//// INTERNAL PROTOCOL FOR RPC

export type RecordingRPCStatus = "ok" | "error"

export type RecordingRPCErrorCode =
  | "not-found"
  | "invalid-state"
  | "unhandled-error"

export interface RecordingRPCError {
  code: RecordingRPCErrorCode
  message: string
}

export interface RecordingRPCRequest extends RecordingMessage {
  rpcId: string
}

export interface RecordingRPCResponse extends RecordingRPCRequest {
  rpcId: string
  status: RecordingRPCStatus
  error?: RecordingRPCError
}

//// MESSAGES

export interface RecordingSync extends RecordingMessage {
  kind: "recording-sync"
  payload: {
    recordingId: string
  }
}

// A message to sync all pending recordings with the backend storage.
export interface RecordingSyncPending extends RecordingMessage {
  kind: "recording-sync-pending"
}

export interface Stop extends RecordingMessage {
  kind: "stop"
}

//// RPC REQUESTS/RESPONSES

export interface RecordingStatusRequest extends RecordingMessage {
  kind: "recording-status-request"
  payload: {
    recordingId: string
  }
}

export interface RecordingStatusResponse extends RecordingMessage {
  kind: "recording-status-response"
  payload?: RecordingSyncStatus
}

export interface RecordingSyncInitRequest extends RecordingMessage {
  kind: "recording-sync-init-request"
  payload: {
    recordingId: string
  }
}

export interface RecordingSyncInitResponse extends RecordingMessage {
  kind: "recording-sync-init-response"
  payload: RecordingSyncStatus
}

export type RecordingSyncMessages = RecordingSync

export type RecordingSyncRequests =
  | RecordingStatusRequest
  | RecordingSyncInitRequest

export type RecordingSyncResponses =
  | RecordingStatusResponse
  | RecordingSyncInitResponse

export type RecordingSyncResponseKind = keyof RecordingSyncResponses

//// Utility functions

// Convert a MIME type to a file name
export function mimeTypeToFileName(mimeType: string): string {
  if (mimeType === UNKNOWN_MIME_TYPE) {
    return UNKNOWN_MIME_TYPE_FILE_NAME
  }

  // Replace the '/' in the MIME type with a '.'
  const tempName = mimeType.replace("/", ".")

  // Keep the first part of the string if the string contains a ';'
  return tempName.split(";")[0]
}
