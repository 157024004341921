import React, { useState } from "react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { httpsCallable } from "firebase/functions"
import { ChevronLeft, ChevronRight } from "lucide-react"
import { useForm } from "react-hook-form"
import { useLocation, useNavigate, useParams } from "react-router"
import { useAnalytics } from "use-analytics"
import { FeedbackButtons } from "~/components/FeedbackButtons"
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  RichEditor,
  toast,
} from "~/components/ui"
import {
  useGetNoteById,
  useNoteTransform,
  useRetryNoteTransform,
  useUpdateNote,
} from "~/hooks/useNotes"
import { EditTransformNoteModal } from "~/pages/Notes/EditTransformNoteModal"
import { functions } from "~/services/firebase"
import {
  formatTimestampAsNoteTitleDatePart,
  formatTimestampAsNoteTitleTimePart,
} from "~/utils/helperFunctions"
import { TransformType } from "./types"

const formatText = (text: string) =>
  text.replace(/\*\*/g, "").replace(/\*/g, "-")

export default function NoteTransformPreview() {
  const location = useLocation()
  const navigate = useNavigate()
  const { noteId } = useParams()
  const { track } = useAnalytics()

  const [isEditing, setIsEditing] = useState(false)
  const [feedback, setFeedback] = useState("")

  const transformType: TransformType =
    location.state?.transformType &&
    Object.values(TransformType).includes(location.state.transformType)
      ? location.state.transformType
      : TransformType.Default

  const noteQuery = useGetNoteById({ noteId: noteId! })
  const transformQuery = useNoteTransform({
    noteId: noteId!,
    transformType: transformType,
  })

  const note = noteQuery.data ?? null
  const transform = transformQuery.data ?? null
  const isLoading = noteQuery.isLoading || transformQuery.isLoading

  // Redirect to notes page if note does not exist
  React.useEffect(() => {
    if (noteQuery.error /* || transformQuery.error */) {
      void navigate("/notes")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteQuery.error])

  // Handle transform not available
  const retryNoteTransform = useRetryNoteTransform({
    onSuccess: () => transformQuery?.refetch?.(),
  })

  React.useEffect(() => {
    if (!noteId) return

    if (transformQuery.error) {
      if (transformQuery.error?.message === "TRANSFORM_NOT_FOUND") {
        toast.error("Transform not available")
      } else {
        toast.error("Could not fetch the transform", {
          id: "note-transform-error",
          duration: Infinity,
          action: {
            label: "Retry",
            onClick: () =>
              retryNoteTransform.mutate({
                noteId: noteId!,
                transformType,
              }),
          },
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteId, transformType, transformQuery.error])

  React.useEffect(() => {
    if (transform && (transform?.status !== "ok" || !transform?.text)) {
      toast.error("Transform not available")
    }
  }, [transform])

  // Form
  const transcription = transform?.text ? formatText(transform.text) : ""
  const form = useForm({
    values: {
      transcription,
    },
  })

  const logFeedback = async ({ feedback }: { feedback: string }) => {
    if (!transform) return

    // Get the function reference
    const addLogEvent = httpsCallable(functions, "addLogEvent")

    // Add the log event
    await addLogEvent({
      type: "Transform Feedback",
      properties: {
        noteId,
        feedback,
        promptId: transform?.promptId,
        status: transform?.status,
        version: transform?.version,
        elapsedTimeMs: transform?.metadata.elapsedTimeMs,
        model: transform?.metadata.model,
        requestTokens: transform?.metadata.requestTokens,
        responseTokens: transform?.metadata.responseTokens,
      },
    })

    // Add GA event
    void track("Transform Feedback", {
      feedback: feedback,
      promptId: transform?.promptId,
      version: transform?.version,
    })

    // TODO: Update the note with the feedback
  }

  const feedbackMutation = useMutation({
    mutationFn: logFeedback,
    onMutate: async ({ feedback }) => {
      setFeedback(feedback)

      if (feedback === "dislike") {
        toast.info("We'll do better next time, thank you. 🙏")
      }
    },
  })

  const queryClient = useQueryClient()
  const updateNote = useUpdateNote({
    onSuccess: async () => {
      if (note) {
        await queryClient.invalidateQueries({ queryKey: ["NOTE", note.id] })
      }
      void track("Transform Continue_to_note", {
        promptId: transform?.promptId,
        version: transform?.version,
      })
      void navigate(`/notes/${note?.id}`)
    },
  })

  const cancelClickHandler = async () => {
    setIsEditing(false)
    void track("Transform Navigate_from_preview_to_selection", {
      promptId: transform?.promptId,
      version: transform?.version,
    })
    void navigate(`/notes/${note?.id}/template-selection`)
  }

  const editClickHandler = () => {
    setIsEditing(true)
    void track("Transform Edit_transcription", {
      promptId: transform?.promptId,
      version: transform?.version,
    })
  }

  const continueClickHandler = async () => {
    if (!note) {
      return
    }

    await updateNote.mutateAsync({
      noteId: note.id,
      editedTranscription: transcription,
    })
  }

  return (
    <>
      {note ? (
        <EditTransformNoteModal
          note={note}
          transformType={transformType}
          isOpen={isEditing}
          onOpenChange={setIsEditing}
        />
      ) : null}

      <section className="relative bg-white w-full h-full px-6">
        <div className="flex flex-col h-full">
          <header className="flex justify-center items-center mt-1 mb-6 gap-1">
            <h2 className="font-platypi text-2xl pr-3">
              Happy with the result?
            </h2>

            <FeedbackButtons
              feedback={feedback}
              onFeedback={(feedbackType) => {
                feedbackMutation.mutate({ feedback: feedbackType })
              }}
            />
          </header>

          <div className="w-fit">
            <p className="pb-3">
              <span className="text-primary-black font-mono tracking-wide text-lg font-bold capitalize">
                {note?.createdAt
                  ? formatTimestampAsNoteTitleDatePart(
                      note?.sessionStart ?? note.createdAt
                    )
                  : ""}
              </span>
              <span> · </span>
              <span className="text-gray-500 font-mono tracking-wide text-lg font-medium">
                {note?.createdAt
                  ? formatTimestampAsNoteTitleTimePart(
                      note?.sessionStart ?? note.createdAt
                    )
                  : ""}
              </span>
            </p>
          </div>

          <div className="flex-1 overflow-auto">
            <Form form={form}>
              <FormField
                control={form.control}
                name="transcription"
                rules={{
                  required: "Transcription is required",
                }}
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <RichEditor
                        {...field}
                        readOnly={true}
                        placeholder={"Your formatted note will appear here..."}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </Form>
          </div>

          <div className="flex items-center justify-center w-full">
            <div className="flex flex-row absolute justify-center items-center gap-2 w-full note-edit-controls-bg pt-5 pb-10 mb-10">
              <Button
                variant="outline"
                className="border-0 h-12 p-2 rounded-lg-plus backdrop-blur-md-plus pr-4 bg-medium-grey-600"
                onClick={cancelClickHandler}
              >
                <span className="flex items-center text-[#131B2A] font-archivo text-lg font-medium leading-5-plus">
                  <ChevronLeft size={24} />
                  {isEditing ? "Cancel" : "Back"}
                </span>
              </Button>

              <Button
                variant="secondary"
                className={`h-12 p-2 px-4 rounded-lg-plus backdrop-blur-md-plus bg-[#121A29] ${isEditing ? "invisible" : ""}`}
                onClick={editClickHandler}
                disabled={isLoading}
              >
                <span className="text-white flex items-center font-archivo text-lg font-medium leading-5-plus">
                  Edit text
                </span>
              </Button>

              <Button
                variant="secondary"
                className="h-12 p-2 pl-4 rounded-lg-plus backdrop-blur-md-plus bg-[#121A29]"
                disabled={!transform}
                onClick={continueClickHandler}
              >
                <span className="text-white flex items-center font-archivo text-lg font-medium leading-5-plus">
                  Continue
                  <ChevronRight size={24} />
                </span>
              </Button>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
