import React, { useEffect } from "react"
import * as Sentry from "@sentry/browser"
import { useAnalytics } from "use-analytics"
import { Button, Progress } from "~/components/ui"
import {
  Credenza,
  CredenzaBody,
  CredenzaContent,
  CredenzaFooter,
  CredenzaHeader,
  CredenzaTitle,
} from "~/components/ui/credenza"
import { useAuth } from "~/context/AuthContext"
import { storage } from "~/services/firebase"
import { LocalRecordings } from "~/utils/recordings/local"
import { RemoteRecordingInMemoryBuffer } from "~/utils/recordings/remote"
import { getSyncClient } from "~/utils/recordings/syncClient"

export enum RecordingRetryUploadState {
  INIT = "init",
  UPLOADING = "uploading",
  SW_SYNCING = "sw_syncing",
  SUCCESS = "success",
  FAIL = "fail",
}

interface RecordingRetryUploadDialogProps {
  recordingId?: string
  onDismissed: (status: RecordingRetryUploadState) => void
}

const RecordingRetryUploadDialog: React.FC<RecordingRetryUploadDialogProps> = ({
  recordingId,
  onDismissed,
}) => {
  const { currentUser } = useAuth()
  const { track } = useAnalytics()
  const [uploadingDismissed, setUploadingDismissed] = React.useState(false)
  const [uploadingProgress, setUploadingProgress] = React.useState(0)
  const [uploadState, setUploadState] =
    React.useState<RecordingRetryUploadState>(RecordingRetryUploadState.INIT)

  // Check upload status on interval
  useEffect(() => {
    // Uploading - check upload status on interval
    if (uploadState === RecordingRetryUploadState.SW_SYNCING && recordingId) {
      const timer = setTimeout(() => {
        getSyncClient()
          .call({
            kind: "recording-status-request",
            payload: { recordingId: recordingId },
          })
          .then((resp) => {
            const bytesSynced = resp.payload?.bytesSynced
            const totalBytes = resp.payload?.totalBytes
            if (bytesSynced && totalBytes) {
              setUploadingProgress(Math.round((bytesSynced / totalBytes) * 100))
            }
            if (resp.payload?.state === "synced") {
              setUploadState(RecordingRetryUploadState.SUCCESS)
              void track("Recording Success_force_retry_upload")
            }
          })
          .catch((error) => {
            setUploadState(RecordingRetryUploadState.FAIL)
            Sentry.captureException(error)
          })
      }, 2000)

      return () => {
        clearTimeout(timer)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadState, recordingId])

  const forceRetry = async (recordingId: string) => {
    if (currentUser?.uid === undefined) {
      return
    }
    // Reset the state
    setUploadingDismissed(false)

    void track("Recording User_force_retry_upload")
    try {
      /**
       * Four cases handled here:
       * 1. No service worker, recording available in local storage
       * 2. No service worker, recording not available in local storage -> fail state
       * 3. Service worker active, recording available in local storage
       * 4. Service worker, recording not available in local storage -> fail state
       */

      if (!(await getSyncClient().isActive())) {
        // Case 1,2: No service worker

        // Update the state
        setUploadState(RecordingRetryUploadState.UPLOADING)

        // Fetch the recording from local storage
        const local = new LocalRecordings()
        const blob = await local.get(recordingId)
        if (blob === undefined) {
          console.warn(
            `Failed to sync to remote storage, missing local data for ${recordingId}`
          )
          throw new Error(`No local recording found for note ${recordingId}`)
        }

        // Create a new remote recording
        const remote = new RemoteRecordingInMemoryBuffer(
          storage,
          currentUser.uid,
          recordingId,
          blob.type,
          (newStatus) => {
            if (newStatus.totalBytes) {
              setUploadingProgress(
                Math.round(
                  (newStatus.bytesWritten / newStatus.totalBytes) * 100
                )
              )
            }
            if (newStatus.state === "error") {
              throw new Error(newStatus.errorMessage || "Unknown error")
            }
            if (newStatus.state === "synced") {
              setUploadState(RecordingRetryUploadState.SUCCESS)
              void track("Recording Success_force_retry_upload")
            }
          }
        )

        // Write to the remote storage and ensure we close so all writes are eventually synced
        try {
          await remote.write(blob)
        } finally {
          await remote.close()
        }
      } else {
        // Case 3,4: Service worker active

        // Update the state
        setUploadState(RecordingRetryUploadState.SW_SYNCING)

        // Initiate the sync
        const resp = await getSyncClient().call({
          kind: "recording-sync-init-request",
          payload: { recordingId: recordingId },
        })

        // Update progress
        const bytesSynced = resp.payload?.bytesSynced
        const totalBytes = resp.payload?.totalBytes
        if (bytesSynced && totalBytes) {
          setUploadingProgress(Math.round((bytesSynced / totalBytes) * 100))
        } else {
          setUploadingProgress(0)
        }
      }
    } catch (error) {
      setUploadState(RecordingRetryUploadState.FAIL)
      Sentry.captureException(error)
      void track("Recording Force_retry_upload_error")
    }
  }

  React.useEffect(() => {
    if (recordingId) {
      void forceRetry(recordingId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordingId])

  return (
    <Credenza
      open={!uploadingDismissed}
      onOpenChange={() => {
        setUploadingDismissed(true)
        onDismissed(uploadState)
      }}
    >
      <CredenzaContent className={""}>
        <CredenzaHeader className="space-y-0.5">
          <CredenzaTitle>Retrying upload</CredenzaTitle>
        </CredenzaHeader>
        <CredenzaBody
          data-vaul-no-drag=""
          className="overflow-y-auto max-h-96 md:max-h-[27rem] px-4"
        >
          <div className="flex flex-col justify-center">
            {uploadState === RecordingRetryUploadState.SUCCESS && (
              <p className="mb-2">
                Upload successful. Your note is now being typed up.
              </p>
            )}

            {uploadState === RecordingRetryUploadState.FAIL && (
              <p className="mb-2">
                Upload failed. Please try again or talk to support.
              </p>
            )}

            {(uploadState === RecordingRetryUploadState.UPLOADING ||
              uploadState === RecordingRetryUploadState.SW_SYNCING ||
              uploadState === RecordingRetryUploadState.INIT) && (
              <div className="flex flex-col justify-center">
                <p className="mb-2">Upload in progress.</p>
                {
                  <div className="flex flex-row gap-1 items-center justify-between">
                    <Progress value={uploadingProgress} />
                    <p className="text-sm font-medium text-gray-500">
                      {uploadingProgress}%
                    </p>
                  </div>
                }
              </div>
            )}
          </div>
        </CredenzaBody>
        <CredenzaFooter className="">
          <Button
            variant="ghost"
            onClick={(e) => {
              e.preventDefault()
              onDismissed(uploadState)
            }}
            className=""
          >
            Close
          </Button>
        </CredenzaFooter>
      </CredenzaContent>
    </Credenza>
  )
}

export default RecordingRetryUploadDialog
