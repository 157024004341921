import { FirebaseError } from "@firebase/util"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  query,
  QuerySnapshot,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore"
import { DateTime, Duration } from "luxon"
import { Observable } from "rxjs"
import { useAnalytics } from "use-analytics"
import { useAuth } from "~/context/AuthContext"
import type { MutationOptions, QueryParameter } from "~/lib/react-query"
import { db } from "~/services/firebase"
import { useSubscription } from "./useSubscription"
import { fromRef } from "./utils"

export type Session = {
  id: string
  start: DateTime
  end?: DateTime
  duration: Duration
  title: string
  description: string
  personal: boolean
  clientId?: string
  clientName?: string
  preRead?: string
  noteIds?: string[]
  recordingDismissed?: boolean
  proposalId?: string
  acceptedAt?: Timestamp
}

type StoredSession = {
  start: Timestamp
  end: Timestamp
  duration: string
  title: string
  description: string
  personal: boolean
  clientId?: string
  clientName?: string
  preRead?: string
  noteIds?: string[]
  recordingDismissed?: boolean
  proposalId?: string
  acceptedAt?: Timestamp
}

function toStoredSession(session: Session): StoredSession {
  const end = session.start.plus(session.duration)
  return {
    start: Timestamp.fromDate(session.start.toJSDate()),
    end: Timestamp.fromDate(end.toJSDate()),
    duration: session.duration.toISO() ?? "",
    title: session.title,
    description: session.description,
    personal: session.personal,
    ...(session.clientId && { clientId: session.clientId }),
    ...(session.clientName && { clientName: session.clientName }),
    ...(session.preRead && { preRead: session.preRead }),
    ...(session.noteIds && { noteIds: session.noteIds }),
    ...(session.recordingDismissed && {
      recordingDismissed: session.recordingDismissed,
    }),
    ...(session.proposalId && { proposalId: session.proposalId }),
    ...(session.acceptedAt && { acceptedAt: session.acceptedAt }),
  }
}

function toSession(sessionId: string, storedSession: StoredSession): Session {
  const parseArray = (array: unknown) => {
    if (!array) return []
    if (Array.isArray(array)) return array
    return typeof array === "string" ? [array] : []
  }

  return {
    id: sessionId,
    start: DateTime.fromJSDate(storedSession.start.toDate()),
    end: storedSession.end
      ? DateTime.fromJSDate(storedSession.end.toDate())
      : undefined,
    duration: Duration.fromISO(storedSession.duration),
    title: storedSession.title,
    description: storedSession.description,
    personal: storedSession.personal,
    clientId: storedSession.clientId,
    clientName: storedSession.clientName,
    preRead: storedSession.preRead,
    noteIds: parseArray(storedSession.noteIds),
    recordingDismissed: storedSession.recordingDismissed,
    proposalId: storedSession.proposalId,
    acceptedAt: storedSession.acceptedAt,
  }
}

type WeekString = `${number}W${number}`

export function toWeekString(date: Date | DateTime): WeekString {
  const dt = date instanceof DateTime ? date : DateTime.fromJSDate(date)
  return `${dt.weekYear}W${dt.weekNumber}`
}

export function fromWeekString(weekString: WeekString): DateTime {
  const [year, week] = weekString.split("W")

  return DateTime.fromObject({
    weekYear: Number(year),
    weekNumber: Number(week),
  }).startOf("week")
}

/* Queries */
export function useSessions({ weekString }: { weekString: WeekString }) {
  const { currentUser } = useAuth()

  const date = fromWeekString(weekString)

  const getAdjustedDateRange = (date: DateTime) => {
    const startOfMonth = date.startOf("month")
    const endOfMonth = date.endOf("month")

    // Adjust the start and end date to start and end of the week
    const adjustedStart = startOfMonth.startOf("week")
    const adjustedEnd = endOfMonth.endOf("week")

    return {
      start: adjustedStart.toJSDate(),
      end: adjustedEnd.toJSDate(),
    }
  }

  return useSubscription<QuerySnapshot, FirebaseError, Session[]>({
    subscriptionKey: ["SESSIONS", date.toFormat("yyyy-MM")],
    subscriptionFn: () => {
      const { start, end } = getAdjustedDateRange(date)

      return fromRef(
        query(
          collection(db, `users/${currentUser?.uid}/sessions`),
          where("start", ">=", start),
          where("start", "<=", end)
        )
      ) as Observable<QuerySnapshot>
    },
    options: {
      select: (snapshot) => {
        return snapshot?.docs?.map((doc) => {
          const storedSession = doc.data() as StoredSession
          return toSession(doc.id, storedSession)
        })
      },
    },
  })
}

export function useSessionById({
  sessionId,
  reactQuery,
}: QueryParameter<unknown> & {
  sessionId: string
}) {
  const { currentUser } = useAuth()

  return useQuery({
    ...reactQuery,
    queryKey: ["SESSIONS", sessionId],
    queryFn: async () => {
      const sessionRef = doc(
        db,
        `users/${currentUser?.uid}/sessions/${sessionId}`
      )
      const sessionSnapshot = await getDoc(sessionRef)

      if (!sessionSnapshot.exists()) {
        throw new Error("SESSION_NOT_FOUND")
      }

      const storedSession = sessionSnapshot.data() as StoredSession
      const session = toSession(sessionSnapshot.id, storedSession)

      return session
    },
  })
}

/* Mutations */
export function useAddSession(
  options?: MutationOptions<{
    session: Session
  }>
) {
  const { currentUser } = useAuth()
  const { track } = useAnalytics()

  return useMutation({
    ...options,
    mutationKey: ["SESSIONS"],
    mutationFn: async ({ session }) => {
      const sessionsRef = collection(db, `users/${currentUser!.uid}/sessions`)

      const sessionsDoc = toStoredSession(session)

      const sessionDocRef = await addDoc(sessionsRef, sessionsDoc)

      return sessionDocRef.id
    },
    onSettled: async (_, error, ..._props) => {
      options?.onSettled?.(_, error, ..._props)
      if (!error) {
        void track("Session Created")
      }
    },
  })
}

export function useUpdateSession(
  options?: MutationOptions<{
    sessionId: string
    session: Session
  }>
) {
  const { currentUser } = useAuth()
  const { track } = useAnalytics()

  return useMutation({
    ...options,
    mutationKey: ["UPDATE_SESSION"],
    mutationFn: async ({ sessionId, session }) => {
      const sessionRef = doc(
        db,
        `users/${currentUser?.uid}/sessions/${sessionId}`
      )
      await updateDoc(sessionRef, {
        ...toStoredSession(session),
      })
    },
    onSettled: (_, error, ...props) => {
      options?.onSettled?.(_, error, ...props)
      void track("Session Updated")
    },
  })
}

export function useDeleteSession(
  options?: MutationOptions<{
    sessionId: string
  }>
) {
  const queryClient = useQueryClient()
  const { currentUser } = useAuth()
  const { track } = useAnalytics()

  return useMutation({
    ...options,
    mutationFn: async ({ sessionId }) => {
      const sessionRef = doc(
        db,
        `users/${currentUser?.uid}/sessions/${sessionId}`
      )
      await deleteDoc(sessionRef)
    },
    onSettled: (_, error, ...props) => {
      if (!error) {
        void track("Session Deleted")
        void queryClient.invalidateQueries({ queryKey: ["SESSIONS"] })
      }

      options?.onSettled?.(_, error, ...props)
    },
  })
}
