import { getBlob, ref } from "firebase/storage"
import { Note, NoteStatus } from "~/pages/Notes/types" // TODO : Move these types to a better location
import { storage } from "~/services/firebase"
import { downloadAudioBlob, LocalRecordings } from "~/utils/recordings/local"

export const downloadLocalRecording = async ({
  id,
  name,
}: {
  id: string
  name?: string
}) => {
  const local = new LocalRecordings()
  const audioBlob = await local.get(id)

  if (!audioBlob) {
    throw new Error(`No local recording found with id ${id}`)
  }

  const baseName = name ?? new Date().toLocaleString()
  const extension = audioBlob.type.replace(/^audio\/([a-zA-Z0-9]+).*$/, ".$1")
  downloadAudioBlob(audioBlob, `${baseName}.${extension}`)
}

export const downloadServerRecording = async ({
  storageURL,
}: {
  storageURL: string
}) => {
  const storageRef = ref(storage, storageURL)
  const audioBlob = await getBlob(storageRef)
  downloadAudioBlob(audioBlob, storageRef.name)
}

export async function downloadRecording(note: Note) {
  switch (note.status) {
    case NoteStatus.Recording:
    case NoteStatus.Uploading:
      await downloadLocalRecording({ id: note.id, name: note.title })
      break
    case NoteStatus.Error:
      try {
        await downloadLocalRecording({ id: note.id, name: note.title })
      } catch (error) {
        console.error(error)
        await downloadServerRecording({ storageURL: note.storageRef })
      }
      break
    default:
      await downloadServerRecording({ storageURL: note.storageRef })
      break
  }
}
