import React, { useEffect, useState } from "react"
import { InlineCheckbox } from "~/components/base/inline-checkbox"
import ClientModal from "~/components/ClientModal"
import { Button, Input, Label } from "~/components/ui"
import { useBooking } from "~/context/BookingContext"

interface TitleClientDetailsProps {
  clientName: string | undefined
  onTitleEnter: () => void
}

const TitleClientDetails: React.FC<TitleClientDetailsProps> = ({
  clientName,
  onTitleEnter,
}) => {
  const { currentSession, updateCurrentSession } = useBooking()
  const [title, setTitle] = useState<string | undefined>(currentSession?.title)
  const [description, setDescription] = useState<string | undefined>(
    currentSession?.description
  )

  const [showClientModal, setShowClientModal] = useState<boolean>(false)

  useEffect(() => {
    updateCurrentSession({ title: title, description: description })
  }, [updateCurrentSession, title, description])

  // Update title if clientName changes and title is not set
  useEffect(() => {
    setTitle(
      currentSession && currentSession.title.length === 0
        ? (clientName ?? "")
        : ""
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientName])

  // Update title if session title changes
  useEffect(() => {
    setTitle(currentSession?.title)
  }, [currentSession?.title])

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setTitle(e.target.value)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === "Enter") {
      e.preventDefault()
      e.stopPropagation()
      onTitleEnter()
    }
  }

  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setDescription(e.target.value)
  }

  const handleClientChange = (clientId: string) => {
    updateCurrentSession({ clientId, personal: false })
  }

  const handlePersonalChange = () => {
    const personal = !currentSession?.personal
    if (personal) {
      updateCurrentSession({ clientId: "undefined", clientName: "", personal })
    } else {
      updateCurrentSession({ personal })
    }
  }

  return (
    <div className="flex flex-col gap-2 sm:gap-4">
      <ClientModal
        sessionId={"sessionId"}
        isOpen={showClientModal}
        onOpenChange={setShowClientModal}
        onClientSet={handleClientChange}
      />
      <div className="flex flex-row gap-2 sm:gap-4">
        <InlineCheckbox
          id="personal"
          checked={currentSession?.personal}
          defaultChecked={true}
          onCheckedChange={handlePersonalChange}
          label={<p className="font-medium font-['Archivo']">Personal</p>}
        />

        <Button
          variant="secondary"
          className="py-3 px-5 rounded-lg-plus font-medium font-['Archivo']"
          onClick={(event) => {
            event.stopPropagation()
            setShowClientModal(true)
          }}
        >
          {currentSession?.clientId ? "Change client" : "Set client"}
        </Button>
      </div>

      <div className="grid w-full max-w-sm items-center gap-1.5">
        <Label htmlFor="title">Title</Label>
        <Input
          type="text"
          value={title}
          onChange={handleTitleChange}
          placeholder={"Add title"}
          className="border-none"
          onFocus={(e) => e.target.select()}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            handleKeyDown(e)
          }}
        />
      </div>
      <div className="grid w-full max-w-sm items-center gap-1.5">
        <Label htmlFor="description">Description</Label>
        <Input
          type="text"
          value={description}
          onChange={handleDescriptionChange}
          placeholder="Optional"
          className="border-none"
        />
      </div>
    </div>
  )
}

export default TitleClientDetails
