import { useEffect } from "react"
import { useNavigate } from "react-router"
import { useAnalytics } from "use-analytics"

type UseInactivityRedirectOptions = {
  timeoutMs?: number
  redirectPath?: string
  source?: string // Used for tracking only
}

export function useInactivityRedirect({
  timeoutMs = 15 * 60 * 1000, // 15 minutes
  redirectPath = "/third-page",
  source = "",
}: UseInactivityRedirectOptions = {}) {
  const navigate = useNavigate()
  const { track } = useAnalytics()

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>

    const handleInactivity = () => {
      const cleanedRedirectPath = redirectPath?.replace(/\//g, "")
      const cleanedSource = source?.replace(/\//g, "")
      void track(
        `Inactivity Redirect${cleanedSource ? "_from" + cleanedSource : ""}_to_${cleanedRedirectPath}`
      )
      void navigate(redirectPath)
    }

    const resetTimer = () => {
      clearTimeout(timer)
      timer = setTimeout(handleInactivity, timeoutMs)
    }

    // List any event you consider “activity”
    const events = ["mousemove", "keydown", "click", "scroll", "touchstart"]

    events.forEach((evt) => window.addEventListener(evt, resetTimer))

    // Start the initial timer
    resetTimer()

    return () => {
      clearTimeout(timer)
      events.forEach((evt) => window.removeEventListener(evt, resetTimer))
    }
  }, [navigate, timeoutMs, redirectPath, source, track])
}
