import React, { useEffect, useState } from "react"
import { Heart, LoaderCircle, MessageSquareWarning } from "lucide-react"
import { useLocation, useNavigate } from "react-router"
import { Button } from "~/components/ui"

const SUBSCRIPTION_SUCCESS = "success"
const SUBSCRIPTION_CANCELED = "canceled"

const SuccessMessage = () => (
  <>
    <Heart
      color="white"
      fill="#d36c65"
      size={96}
    />
    <h1 className="text-4xl font-medium">Joy Notes Subscription</h1>
    <p className="text-lg text-center">
      Your subscription has been successfully updated!
    </p>
  </>
)

const CancelationMessage = () => (
  <>
    <MessageSquareWarning
      color="white"
      fill="#d36c65"
      size={96}
    />
    <h1 className="text-4xl font-medium">
      Your subscription has been cancelled
    </h1>
    <p className="text-lg text-center">
      Let us know if this was not your intention!
    </p>
  </>
)

export default function Subscription() {
  const navigate = useNavigate()
  const location = useLocation()

  const [isLoading, setIsLoading] = useState(false)
  const [subscriptionResult, setSubscriptionResult] = useState<string | null>(
    null
  )

  // Parse the subscription result from the URL query string
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const result = urlParams.get("result")
    setSubscriptionResult(result)
  }, [location.search])

  const handleNavigation = (path: string) => {
    setIsLoading(true)
    navigate(path, { replace: true })
    setTimeout(() => {
      setIsLoading(false)
    }, 5000)
  }

  return (
    <div className="flex h-screen flex-col justify-center items-center gap-2 p-2">
      {subscriptionResult === SUBSCRIPTION_SUCCESS && <SuccessMessage />}
      {subscriptionResult === SUBSCRIPTION_CANCELED && <CancelationMessage />}

      <Button
        className="bg-blue-700 hover:bg-blue-900 text-white my-2"
        onClick={() => handleNavigation("/")}
      >
        {isLoading && <LoaderCircle />}
        Take me back to my account
      </Button>
    </div>
  )
}
