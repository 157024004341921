import React from "react"
import { type Session } from "~/hooks/firestore/useSessions"

interface HomeHeaderProps {
  remainingSessions: Session[]
  minutesToNextSession: number
  isSessionOngoing: boolean
  sessionsToday: Session[]
}

const HomeHeader: React.FC<HomeHeaderProps> = ({
  remainingSessions,
  minutesToNextSession,
  isSessionOngoing,
  sessionsToday,
}) => {
  return (
    <header className="text-white space-y-2 sm:space-y-4 md:space-y-8">
      {remainingSessions.length > 0 && minutesToNextSession < 6 ? (
        <h1 className="font-semibold text-5xl md:text-[46px] tracking-[-1.73px] leading-[1] drop-shadow">
          {isSessionOngoing
            ? `${remainingSessions[0].personal ? remainingSessions[0].title : "Session in progress"}`
            : minutesToNextSession === 0
              ? `${remainingSessions[0].title} starts now`
              : `${remainingSessions[0].title} in ${minutesToNextSession} min`}
        </h1>
      ) : sessionsToday.length === 0 ? (
        <>
          <h2 className="font-semibold text-5xl md:text-[46px] tracking-[-1.73px] leading-[1] drop-shadow">
            No sessions today
          </h2>
          <p className="font-semibold text-2xl md:text-[28px] tracking-[-1.45px] leading-[1] drop-shadow">
            Add client sessions <br /> to the calendar,
            <br /> get prep notes <br /> just in time
          </p>
        </>
      ) : remainingSessions.length === 0 ? (
        <>
          <h2 className="font-semibold text-5xl md:text-[46px] tracking-[-1.73px] leading-[1] drop-shadow">
            No more sessions today
          </h2>
          <p className="font-semibold text-2xl md:text-[28px] tracking-[-1.45px] leading-[1] drop-shadow">
            Well done!
          </p>
        </>
      ) : (
        <h2 className="font-semibold text-4xl sm:text-5xl md:text-[46px] tracking-[-1.73px] leading-[1] drop-shadow">
          {remainingSessions.length === 1 && "One session left today"}
          {remainingSessions.length > 1 &&
            `${remainingSessions.length} more sessions today`}
        </h2>
      )}
    </header>
  )
}

export default HomeHeader
