import React from "react"
import { zodResolver } from "@hookform/resolvers/zod"
import { captureException } from "@sentry/react"
import { ArrowLeftIcon } from "lucide-react"
import { useForm } from "react-hook-form"
import { Link, useNavigate } from "react-router"
import { BaseControl } from "~/components/base/base-control"
import BoxContainer from "~/components/BoxContainer"
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
  Form,
  Input,
  SubmitButton,
} from "~/components/ui"
import { resetPassword } from "~/lib/auth"
import { z, zodErrorMap } from "~/lib/zod"

const formSchema = z.object({
  email: z
    .string()
    .min(1, { message: "This field is required" })
    .email({ message: "Invalid email address" }),
})

export default function ResetPassword() {
  const navigate = useNavigate()

  const [open, setOpen] = React.useState(false)
  const form = useForm({
    resolver: zodResolver(formSchema, { errorMap: zodErrorMap }),
    defaultValues: {
      email: "",
    },
  })

  return (
    <>
      <BoxContainer>
        <header className="flex flex-col gap-2 mb-6">
          <h2 className="text-2xl font-bold">Forgot your password?</h2>
          <p>
            Enter your email address and we will send you a link to reset your
            password.
          </p>
        </header>

        <Form
          form={form}
          className="space-y-6"
          onSubmit={form.handleSubmit(async (values) => {
            try {
              await resetPassword(values.email)
              setOpen(true)
            } catch (err) {
              captureException(err)
            }
          })}
        >
          <BaseControl
            name="email"
            label="Email"
            render={({ field }) => (
              <Input
                {...field}
                type="email"
                placeholder="Enter your email"
              />
            )}
          />

          <div className="space-y-2">
            <SubmitButton
              type="submit"
              className="w-full font-bold"
              isSubmitting={form.formState.isSubmitting}
            >
              Reset password
            </SubmitButton>

            <p className="text-center">
              <Button
                asChild
                size="sm"
                variant="link"
                className="text-foreground hover:underline hover:text-primary"
              >
                <Link to="/login">
                  <ArrowLeftIcon size={16} />
                  Back to login
                </Link>
              </Button>
            </p>
          </div>
        </Form>
      </BoxContainer>

      <AlertDialog
        open={open}
        onOpenChange={(open) => {
          setOpen(open)
          if (!open) {
            void navigate("/")
          }
        }}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Password reset email sent</AlertDialogTitle>
            <AlertDialogDescription>
              Check your inbox for a link to reset your password. If it doesn’t
              appear within a few minutes, check your spam folder.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Continue</AlertDialogCancel>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
