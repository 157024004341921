import { X } from "lucide-react"
import { Button } from "~/components/ui"
import { type Session } from "~/hooks/firestore/useSessions"

export interface NoteReminderCardProps {
  session: Session
  onSessionDismissed: () => void
  onRecordNote: () => void
}

export function NoteReminderCard({
  session,
  onSessionDismissed,
  onRecordNote,
}: NoteReminderCardProps) {
  return (
    <div
      role="button"
      tabIndex={0}
      onClick={onRecordNote}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          onRecordNote()
        }
      }}
      className="relative group transition ease-in-out delay-150 w-full sm:w-[412px] bg-white rounded-[1rem] shadow-box-2 font-deco text-base hover:bg-bright_coral-500 text-gray-900 hover:text-white px-4 sm:px-6 py-3 cursor-pointer ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
    >
      <Button
        tabIndex={-1}
        variant="ghost"
        aria-label="Dismiss session"
        onClick={(e) => {
          e.stopPropagation()
          onSessionDismissed()
        }}
        className="absolute top-[-10px] left-[-10px] rounded-full p-2 bg-white/80 sm:bg-slate-200 shadow z-10 w-[30px] h-[30px] border border-gray-200 transition-opacity opacity-100 sm:opacity-0 sm:group-hover:opacity-90 hover:bg-slate-300"
      >
        <X className="h-4 w-4 text-gray-800" />
      </Button>

      <div className="flex justify-between items-center h-full">
        <h2 className="text-base font-bold tracking-tight truncate">
          {session.title}
        </h2>
        <div className="inline-flex items-center rounded-md text-sm font-medium bg-primary text-primary-foreground h-10 px-4 py-2">
          Record note
        </div>
      </div>
    </div>
  )
}
