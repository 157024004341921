import * as React from "react"
import { toolbarPlugin } from "@mdxeditor/editor"
import { useQueryClient } from "@tanstack/react-query"
import { useForm } from "react-hook-form"
import {
  Button,
  ConfirmationModal,
  desktop,
  Credenza as Dialog,
  CredenzaClose as DialogClose,
  CredenzaContent as DialogContent,
  CredenzaHeader as DialogHeader,
  CredenzaTitle as DialogTitle,
  CredenzaTrigger as DialogTrigger,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  RichEditor,
  ScrollArea,
  SimpleEditorToolbar,
  SubmitButton,
  toast,
  useMediaQuery,
} from "~/components/ui"
import { useBeforeunload } from "~/hooks/useBeforeunload"
import { useTranscribedNote, useUpdateNote } from "~/hooks/useNotes"
import { Note, NoteStatus } from "~/pages/Notes/types"
import {
  formatTimestampAsNoteTitleDatePart,
  formatTimestampAsNoteTitleTimePart,
} from "~/utils/helperFunctions"
import { parseTranscript } from "~/utils/transcriptParsers"

// Add a zero-width space to prevent empty lines from collapsing
const normalizeTranscription = (text: string) =>
  text.replace(/\r\n/g, "\n").replace(/\n\n/g, "\n\u200B\n")

// Replace the zero-width space
const restoreTranscription = (text: string) =>
  text.replace(/\n\u200B\n/g, "\n\n")

export function parseTranscribed(note: Note) {
  switch (note.status) {
    case NoteStatus.Edited:
      return normalizeTranscription(note.editedTranscription)
    case NoteStatus.Improved:
      return normalizeTranscription(note.improvedTranscription)
    case NoteStatus.ImprovementRequested:
      // @note: lazy load the improved transcription later
      return ""
    case NoteStatus.Transcribed:
      return normalizeTranscription(parseTranscript(note.transcription))
    case NoteStatus.Error:
      return `Error transcribing note \n\n ${note.transcription}`
    default:
      return "Not transcribed yet"
  }
}

type EditNoteModalProps = {
  note: Note
  isOpen?: boolean
  onOpenChange?: (isOpen: boolean) => void
}

export function EditNoteModal({
  note,
  isOpen,
  onOpenChange,
  children,
}: React.PropsWithChildren<EditNoteModalProps>) {
  const isDesktop = useMediaQuery(desktop)
  const queryClient = useQueryClient()

  const formRef = React.useRef<HTMLFormElement | null>(null)
  const editorRef = React.useRef<React.ElementRef<typeof RichEditor> | null>(
    null
  )

  const isImprovementRequested = note.status === NoteStatus.ImprovementRequested
  const transcribed = useTranscribedNote({
    note,
    reactQuery: {
      enabled: isImprovementRequested,
    },
  })

  const initialValues = React.useMemo(
    () => ({
      transcription:
        note.status === NoteStatus.ImprovementRequested
          ? (transcribed.data ?? "")
          : parseTranscribed(note),
    }),
    [note, transcribed.data]
  )

  const transcription = initialValues.transcription
  const form = useForm({
    values: {
      transcription,
    },
  })

  // MDXEditor is not a controlled component, so we need to set the markdown
  // by manually.
  React.useEffect(() => {
    if (transcription) {
      editorRef.current?.setMarkdown(transcription)
    }
  }, [transcription])

  /* Actions */
  const updateNote = useUpdateNote({
    onSuccess: async () => {
      toast.success("Note updated", {
        id: "note-updated",
      })

      onOpenChange?.(false)
      await queryClient.invalidateQueries({ queryKey: ["NOTE", note.id] })
    },
  })

  const isDirty = !!form.formState.dirtyFields?.transcription
  const [isWarningModalOpen, setIsWarningModalOpen] = React.useState(false)

  useBeforeunload(() => {
    if (isDirty) {
      return "You have changes to a note that will be lost."
    }
  })

  const discardChanges = () => {
    form.reset({ ...initialValues })
    // We need to do a more step here, reset the MDXEditor
    editorRef.current?.setMarkdown(initialValues.transcription)
  }

  const handleSubmit = form.handleSubmit(async (values) => {
    const restoredTranscription = restoreTranscription(values.transcription)
    await updateNote.mutateAsync({
      noteId: note.id,
      editedTranscription: restoredTranscription,
    })
    form.reset()
  })

  return (
    <>
      <Dialog
        shouldScaleBackground={false}
        open={isOpen}
        onOpenChange={(open) => {
          if (updateNote.isPending) return

          if (!open && isDirty) {
            setIsWarningModalOpen(true)
            return
          }

          onOpenChange?.(open)
        }}
        className="flex flex-col min-h-0"
      >
        <DialogTrigger asChild>{children}</DialogTrigger>
        <DialogContent
          {...(isDesktop ? { closeable: false } : { indicator: true })}
          aria-describedby=""
          overlayClassName={"overflow-y-hidden p-0"}
          className={
            "flex flex-col md:rounded-none border-0 after:!content-none md:inset-x-auto md:bottom-auto md:w-full md:max-w-full h-[90%] md:h-full"
          }
          onKeyPress={(e) => {
            if (!e.target?.hasAttribute?.("contenteditable")) {
              editorRef.current?.focus()
            }
          }}
        >
          <DialogHeader className="sr-only">
            <DialogTitle>Edit Note</DialogTitle>
          </DialogHeader>

          <Form
            ref={formRef}
            form={form}
            key={isOpen ? "open" : "closed"} // Reset the form when the modal is closed
            data-vaul-no-drag=""
            className="flex-1 flex flex-col w-full lg:w-[860px] mx-auto p-4 md:p-0 min-h-0 max-h-[calc(100dvh-3rem)]"
            onSubmit={handleSubmit}
          >
            <header className="shrink-0 grid grid-cols-2 md:flex items-center justify-between mb-2 sm:mb-4 gap-1 sm:gap-2">
              <DialogClose asChild>
                <Button
                  type="button"
                  variant="default"
                  data-invisible={!isDirty ? "true" : undefined}
                  className="justify-self-center rounded-full min-w-[100px]  min-h-[40px] sm:min-h-[49px] bg-transparent_gray text-black hover:bg-transparent_gray data-[invisible]:invisible"
                >
                  Cancel
                </Button>
              </DialogClose>

              <div className="order-[-1] md:order-none col-span-2 text-center">
                <h2 className="font-sans text-[17px] text-primary-black leading-snug font-bold">
                  {note?.clientId ? note?.title : "Note"}
                </h2>
                <p className="flex items-center justify-center gap-1 text-gray-500 font-sans text-[14px] md:text-[17px] leading-snug">
                  <span className="capitalize">
                    {formatTimestampAsNoteTitleDatePart(
                      note?.sessionStart ?? note.createdAt
                    )}
                  </span>
                  <span> · </span>
                  <span className="font-medium">
                    {formatTimestampAsNoteTitleTimePart(
                      note?.sessionStart ?? note.createdAt
                    )}
                  </span>
                </p>
              </div>

              <SubmitButton
                type="submit"
                variant="secondary"
                className="justify-self-center rounded-full min-w-[100px] min-h-[40px] sm:min-h-[49px]"
                isSubmitting={form.formState.isSubmitting}
                onClick={() => {
                  if (isDirty) {
                    formRef.current?.requestSubmit?.()
                  } else {
                    onOpenChange?.(false)
                  }
                }}
              >
                {isDirty ? "Save" : "Done"}
              </SubmitButton>
            </header>

            <ScrollArea className="flex-1 min-h-0 lg:w-[860px] border rounded-md p-2">
              <FormField
                control={form.control}
                name="transcription"
                rules={{
                  required: "Transcription is required",
                }}
                render={({ field }) => (
                  <FormItem className="flex flex-col flex-1 min-h-0">
                    <FormControl className="min-h-0">
                      <RichEditor
                        {...field}
                        ref={(ref) => {
                          field.ref(ref)
                          editorRef.current = ref
                        }}
                        readOnly={
                          isImprovementRequested && transcribed.isLoading
                        }
                        onRequestSave={() => {
                          if (form.formState.isDirty) {
                            formRef.current?.requestSubmit?.()
                          }
                        }}
                        plugins={[
                          toolbarPlugin({
                            toolbarClassName:
                              "mdx-toolbar bg-light_gray/80 backdrop-blur rounded-md px-2.5 py-2 sticky top-auto bottom-0 sm:bottom-2 inset-x-0 mx-auto w-[112px] mb-4 overflow-hidden",
                            toolbarContents: SimpleEditorToolbar,
                          }),
                        ]}
                        placeholder={"Edit your note here"}
                        className="flex-1 flex flex-col-reverse w-full min-h-0"
                        contentEditableClassName="flex-1 font-sourceSerifPro leading-[31.16px] text-[17px] sm:text-[19px] min-h-0 p-0"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </ScrollArea>
          </Form>
        </DialogContent>
      </Dialog>

      <ConfirmationModal
        isOpen={isWarningModalOpen}
        onOpenChange={setIsWarningModalOpen}
        title="Unsaved changes"
        description="You have unsaved changes. If you continue now, your edits will be lost."
        closeButton="Keep editing"
        confirmButton="Discard changes"
        onClose={() => {
          setIsWarningModalOpen(false)
        }}
        onConfirm={() => {
          discardChanges()
          onOpenChange?.(false)
          setIsWarningModalOpen(false)
        }}
      />
    </>
  )
}
