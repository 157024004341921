import { useEffect, useMemo, useReducer, useState } from "react"
import { Timestamp } from "@firebase/firestore"
import * as Sentry from "@sentry/browser"
import { Crisp } from "crisp-sdk-web"
import { DateTime, Duration } from "luxon"
import { useNavigate } from "react-router"
import { useAnalytics } from "use-analytics"
import LogOut from "~/assets/icons/logout-icon.svg?react"
import { Button, ConfirmationModal, useMediaQuery } from "~/components/ui"
import {
  toWeekString,
  useSessions,
  useUpdateSession,
  type Session,
} from "~/hooks/firestore/useSessions"
import useSafeLogout from "~/hooks/useSafeLogout"
import HomeHeader from "./HomeHeader"
import HomeNavButtons from "./HomeNavButtons"
import { NoteReminderCard } from "./NoteReminderCard"
import { PrepSessionCard } from "./PrepSessionCard"

const TALL_SCREEN = "(min-height: 850px)"

const LogoutButton = ({ onLogout }) => {
  return (
    <Button
      onClick={onLogout}
      size="sm"
      variant="ghost"
      className="self-end sm:self-start hover:bg-[#ADDEBA] hover:bg-opacity-60"
    >
      <p className="text-white">Logout</p>
      <LogOut className="size-5 mr-1 fill-white" />
    </Button>
  )
}

export function Home() {
  const { localRecordingsFound, tryLogout, forceLogout } = useSafeLogout()
  const { track } = useAnalytics()
  const [, forceRerender] = useReducer((x) => x + 1, 0)
  const navigate = useNavigate()
  const isTallScreen = useMediaQuery(TALL_SCREEN)

  const sessionsQuery = useSessions({
    weekString: toWeekString(DateTime.local()),
  })

  const updateSession = useUpdateSession({
    onSettled: (_, error) => {
      if (error) {
        // Log error quietly
        Sentry.captureException(error)
      }
    },
  })

  const sessions = sessionsQuery.data
  const [showLocalRecordingsAlert, setShowLocalRecordingsAlert] =
    useState(false)

  const confirmLogout = async () => {
    setShowLocalRecordingsAlert(false)
    await forceLogout()
  }

  const handleLogout = async () => {
    void track("Home Logout")
    const res = await tryLogout()
    if (!res && localRecordingsFound) {
      setShowLocalRecordingsAlert(true)
    }
  }

  // Filter sessions for today using useMemo
  const sessionsToday = useMemo(() => {
    if (!sessions) {
      return []
    }
    const today = DateTime.now().startOf("day")
    const eod = today.endOf("day")
    return sessions
      .filter((session) => {
        return session.start > today && session.start < eod
      })
      .sort((a, b) => a.start.toMillis() - b.start.toMillis())
  }, [sessions])

  // Filter remaining sessions for today, including any ongoing session
  const remainingSessions = useMemo(() => {
    if (!sessionsToday) {
      return []
    }
    const now = DateTime.now()
    return sessionsToday
      .filter((session) => {
        return now < session.start.plus(session.duration)
      })
      .sort((a, b) => a.start.toMillis() - b.start.toMillis())
  }, [sessionsToday])

  // Get active session reminders
  const activeSessionReminders = useMemo(() => {
    if (!sessionsToday) {
      return []
    }
    const now = DateTime.now()
    return sessionsToday
      .filter((session) => {
        // Show reminder if session is 75% complete, has no notes, and has not been dismissed
        const partialDuration = Duration.fromMillis(
          session.duration.as("milliseconds") * 0.75
        )
        return (
          now > session.start.plus(partialDuration) &&
          (session.noteIds?.length ?? 0) === 0 &&
          !session.recordingDismissed &&
          session.clientId
        )
      })
      .sort((a, b) => b.start.toMillis() - a.start.toMillis())
      .slice(0, isTallScreen ? 3 : 1)
  }, [sessionsToday, isTallScreen])

  const handleNoteReminderDismissed = (session: Session) => {
    void track("Home Dismiss_recording_reminder")
    const updatedSession = { ...session, recordingDismissed: true }
    updateSession.mutate({ sessionId: session.id, session: updatedSession })
  }

  const handleRecordNoteForSession = (session: Session) => {
    void track(`Home Record_from_reminder`)
    const sessionStart = session?.start.toJSDate()
    const state = {
      clientId: session?.clientId,
      clientName: session?.clientName,
      title: session?.title,
      sessionId: session?.id,
      sessionStart: sessionStart ? Timestamp.fromDate(sessionStart) : undefined,
    }
    void navigate("/recorder", { state })
  }

  // Update every minute to reflect the current time
  useEffect(() => {
    const interval = setInterval(forceRerender, 60000)
    return () => clearInterval(interval)
  }, [sessionsQuery])

  useEffect(() => {
    const closeAndHideChat = () => {
      if (Crisp.chat.isChatOpened()) {
        Crisp.chat.close()
      }
      if (Crisp.chat.isVisible()) {
        Crisp.chat.hide()
      }
    }

    // Set a timeout to close the chat after 2 seconds
    const timeout = setTimeout(() => {
      closeAndHideChat()
    }, 2000)

    // Close and hide chat on component mount
    closeAndHideChat()

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  const minutesToNextSession =
    remainingSessions.length > 0
      ? Math.round(remainingSessions[0].start.diffNow("minutes").minutes)
      : 0

  const isSessionOngoing =
    remainingSessions.length > 0 && minutesToNextSession < 0

  return (
    <section className="h-full overflow-hidden bg-cover bg-center bg-no-repeat bg-img-forest">
      <ConfirmationModal
        isOpen={showLocalRecordingsAlert}
        onOpenChange={setShowLocalRecordingsAlert}
        title="Local recordings available"
        description="You have recordings that have not yet been uploaded. They will be deleted if you logout before upload is finished. Are you sure you want to logout?"
        confirmButton="Confirm"
        closeButton="Cancel"
        onConfirm={confirmLogout}
      />

      <div className="container px-3 h-full overflow-auto grid grid-cols-1 xl:grid-cols-2 mx-auto no-scrollbar">
        <div className="h-full w-full flex flex-col justify-between">
          <div className="w-full flex flex-col sm:mt-4">
            <LogoutButton onLogout={handleLogout} />

            <HomeHeader
              remainingSessions={remainingSessions}
              minutesToNextSession={minutesToNextSession}
              isSessionOngoing={isSessionOngoing}
              sessionsToday={sessionsToday}
            />
          </div>

          <div className="space-y-3 mb-2 sm:mb-8 md:mb-12">
            {remainingSessions.length > 0 && (
              <PrepSessionCard session={remainingSessions[0]} />
            )}

            {activeSessionReminders.map((session) => (
              <NoteReminderCard
                key={session.id}
                session={session}
                onSessionDismissed={() => handleNoteReminderDismissed(session)}
                onRecordNote={() => handleRecordNoteForSession(session)}
              />
            ))}

            <HomeNavButtons />
          </div>
        </div>
      </div>
    </section>
  )
}
