import React from "react"
import {
  Button,
  ConfirmationModal,
  Credenza as Dialog,
  CredenzaBody as DialogBody,
  CredenzaContent as DialogContent,
  CredenzaFooter as DialogFooter,
  CredenzaHeader as DialogHeader,
  CredenzaTitle as DialogTitle,
  CredenzaTrigger as DialogTrigger,
  Progress,
} from "~/components/ui"
import { useNavigatorOnLine } from "~/hooks/useNavigatorOnline"
import { SyncState } from "~/utils/recordings/protocol"

export type UploadingState = {
  uploading: boolean
  bytesTransferred: number
  progress: number
  bytesTotal?: number
  syncState?: SyncState
  error?: string
  background?: boolean
}

interface RecordingUploadDialogProps {
  uploadingState: UploadingState
  onRetryUpload: () => void
  onDownloadRecording: () => Promise<void>
  onClosed: () => void
}

export function RecordingUploadDialog({
  uploadingState,
  onRetryUpload,
  onDownloadRecording,
  onClosed,
  children,
}: React.PropsWithChildren<RecordingUploadDialogProps>) {
  const [isOpen, setOpen] = React.useState(true)
  const [showLeaveUploadConfirmation, setShowLeaveUploadConfirmation] =
    React.useState(false)
  const isOnline = useNavigatorOnLine()

  const handleOpenChanged = (isOpen: boolean) => {
    if (!isOpen) {
      if (!uploadingState.background) {
        setShowLeaveUploadConfirmation(true)
      } else {
        setOpen(isOpen)
        onClosed()
      }
    }
  }

  return (
    <>
      <ConfirmationModal
        isDestructive
        isOpen={showLeaveUploadConfirmation}
        onOpenChange={setShowLeaveUploadConfirmation}
        title="Cancel upload"
        description="Leaving now will cancel the upload. Are you sure you want to leave?"
        closeButton="Stay"
        confirmButton="Leave"
        onConfirm={onClosed}
      />

      <Dialog
        open={uploadingState.uploading && isOpen}
        onOpenChange={handleOpenChanged}
        shouldScaleBackground={false}
      >
        <DialogTrigger asChild>{children}</DialogTrigger>

        <DialogContent
          indicator={"false"}
          className="border-0 rounded-2xl after:!content-none inset-x-0 md:inset-x-auto bottom-0 md:bottom-auto"
          aria-describedby=""
        >
          <div className="block md:hidden absolute inset-x-0 mx-auto mt-4 h-2 w-[100px] rounded-full bg-white/80" />

          <DialogHeader>
            <DialogTitle className="text-left text-xl font-bold px-8 md:px-12">
              Uploading
            </DialogTitle>
          </DialogHeader>

          <DialogBody className="text-left py-8 md:py-4 px-8 md:px-12">
            <div className="flex flex-col justify-center">
              {isOnline ? (
                <p className="mb-2 text-balance">
                  Your recording is encrypted and being securely uploaded to our
                  servers.
                </p>
              ) : (
                <div className="mb-2 flex flex-col justify-center">
                  <p className="text-red-500">You are offline</p>
                  <p className="text-sm text-gray-500">
                    Upload will resume when you are back online.
                  </p>
                </div>
              )}
              <p className="text-sm text-gray-500">Progress</p>
              <div className="flex flex-row gap-1 items-center justify-between">
                <Progress value={uploadingState.progress} />
                <p className="text-sm font-medium text-gray-500">
                  {uploadingState.progress}%
                </p>
              </div>
              {uploadingState.syncState === "synced" && (
                <p className="text-sm text-gray-500 text-balanced">
                  Your recording is now being processed on our server. You can
                  close this dialog.
                </p>
              )}
            </div>
            {uploadingState.error && (
              <>
                <p className="text-xl text-red-500 mt-2">
                  Something went wrong
                </p>
                <p className="text-md text-red-500 mb-4">
                  {uploadingState.error}
                </p>
                <div className="flex flex-row gap-1 items-center justify-around">
                  <Button
                    variant={"secondary"}
                    onClick={onRetryUpload}
                  >
                    Retry upload
                  </Button>
                  <Button
                    variant={"secondary"}
                    onClick={onDownloadRecording}
                  >
                    Download file locally
                  </Button>
                </div>
              </>
            )}
          </DialogBody>
          <DialogFooter className="text-center sm:justify-center text-xs text-muted-foreground">
            <p></p>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  )
}
