import {
  ref,
  StorageError,
  uploadBytesResumable,
  uploadString,
  type FirebaseStorage,
  type StorageReference,
} from "firebase/storage"
import {
  mimeTypeToFileName,
  PersistentRecording,
  PersistentRecordingError,
  RecordingSyncStatus,
} from "./protocol"

interface SyncRecordingParams {
  storageRef: StorageReference
  data: Blob
  status: RecordingSyncStatus
  progressCallback: (status: RecordingSyncStatus) => void
}

function syncBlob({
  storageRef,
  data,
  status,
  progressCallback,
}: SyncRecordingParams): Promise<void> {
  return new Promise((resolve, reject) => {
    const uploadTask = uploadBytesResumable(storageRef, data, {
      customMetadata: { contentType: data.type },
    })

    status = {
      ...status,
      state: "syncing",
      totalBytes: data.size,
      updatedAt: new Date(),
    }

    console.debug("Syncing blob to", storageRef.fullPath)
    progressCallback(status)

    uploadTask.on("state_changed", {
      next: (snapshot) => {
        const timestamp = new Date()
        status = {
          ...status,
          bytesSynced: snapshot.bytesTransferred,
          updatedAt: timestamp,
          lastWriteAt: timestamp,
        }
        progressCallback(status)
      },
      error: (error: StorageError) => {
        status = {
          ...status,
          state: "error",
          errorMessage: error.message,
          updatedAt: new Date(),
        }
        progressCallback(status)
        reject(error)
      },
      complete: () => {
        status = {
          ...status,
          state: "synced",
          updatedAt: new Date(),
        }
        progressCallback(status)
        resolve()
      },
    })
  })
}

/** */
export class RemoteRecordingInMemoryBuffer implements PersistentRecording {
  recordingId: string
  usesBackgroundOperations: boolean = false
  accessPath: string

  private storagePath: string
  private storageRef: StorageReference
  private contentType: string
  private info: RecordingSyncStatus
  private isClosed: boolean

  // The sync happens in two steps:
  // 1. write, append the data to the list of chunks
  // 2. close, concatenate and sync the chunks in order to the remote backend
  private chunks: Blob[]

  private progressCallback: (status: RecordingSyncStatus) => void

  constructor(
    storage: FirebaseStorage,
    userId: string,
    recordingId: string,
    contentType: string,
    progressCallback?: (status: RecordingSyncStatus) => void
  ) {
    this.recordingId = recordingId
    this.isClosed = false

    const path = `users/${userId}/recordings/${recordingId}/${mimeTypeToFileName(contentType)}`
    this.storageRef = ref(storage, path)

    this.storagePath = this.storageRef.fullPath

    this.contentType = contentType

    this.chunks = []

    this.accessPath = this.storagePath

    const createdAt = new Date()
    this.info = {
      kind: "recording",
      recordingId,
      bytesWritten: 0,
      bytesSynced: 0,
      totalBytes: 0,
      remotePath: this.storagePath,
      state: "init",
      createdAt,
      updatedAt: createdAt,
      contentType,
    }

    this.progressCallback = progressCallback ?? ((_s) => {})
  }

  /**
   * Upload a new recording
   */
  write(data: Blob): Promise<void> {
    if (this.isClosed) {
      return Promise.reject(
        new PersistentRecordingError("Write failed, recording already closed")
      )
    }

    // return immediately and store in memory until flush is called
    this.chunks.push(data)

    return Promise.resolve()
  }

  status(): Promise<RecordingSyncStatus> {
    return Promise.resolve(this.info)
  }

  close(): Promise<void> {
    this.isClosed = true

    const data = new Blob(this.chunks, { type: this.contentType })

    return syncBlob({
      storageRef: this.storageRef,
      data,
      status: this.info,
      progressCallback: (status: RecordingSyncStatus) => {
        this.info = status
        this.progressCallback(status)
      },
    })
  }

  delete(): Promise<void> {
    return Promise.reject(
      new PersistentRecordingError(
        "Deleting a remote recording is not supported."
      )
    )
  }
}

/** */
export class RemoteChunk implements PersistentRecording {
  recordingId: string
  usesBackgroundOperations: boolean = false
  accessPath: string

  private storagePath: string
  private storageRef: StorageReference
  private info: RecordingSyncStatus
  private isClosed: boolean

  private progressCallback: (status: RecordingSyncStatus) => void

  // Flush is the same as a write, and we only allow a single write for chunks
  private writePromise: Promise<void> | undefined
  private writeResolve!: (value: void | PromiseLike<void>) => void
  private writeReject!: (reason?: unknown) => void

  constructor(
    baseRef: StorageReference,
    recordingId: string,
    chunkId: number,
    progressCallback?: (status: RecordingSyncStatus) => void
  ) {
    this.recordingId = recordingId
    this.isClosed = false

    // This file path has to match the combineChunkedRecording backend function
    this.storageRef = ref(baseRef, `chunk.${chunkId}`)

    this.storagePath = this.storageRef.fullPath
    this.accessPath = this.storagePath

    const createdAt = new Date()
    this.info = {
      kind: "chunk",
      recordingId,
      chunkId,
      bytesWritten: 0,
      bytesSynced: 0,
      totalBytes: 0,
      remotePath: this.storagePath,
      state: "init",
      createdAt,
      updatedAt: createdAt,
      contentType: undefined,
    }

    this.progressCallback = progressCallback ?? ((_s) => {})

    this.writePromise = undefined
  }

  /**
   * Write and upload a new chunk, this can only be called once. If its called
   * more than once, the same promise is always returned.
   */
  async write(data: Blob): Promise<void> {
    if (this.isClosed) {
      return Promise.reject(
        new PersistentRecordingError("Write failed, recording already closed")
      )
    }

    this.info = { ...this.info, contentType: data.type }

    if (this.writePromise !== undefined) {
      return this.writePromise
    }

    this.writePromise = syncBlob({
      storageRef: this.storageRef,
      data,
      status: this.info,
      progressCallback: (status: RecordingSyncStatus) => {
        this.info = status
        this.progressCallback(status)
      },
    })

    return this.writePromise
  }

  status(): Promise<RecordingSyncStatus> {
    return Promise.resolve(this.info)
  }

  close(): Promise<void> {
    this.isClosed = true

    // This means that no writes will be possible for this chunk
    if (this.writePromise === undefined) {
      return Promise.resolve()
    }

    return this.writePromise
  }

  delete(): Promise<void> {
    return Promise.reject(
      new PersistentRecordingError(
        "Deleting a remote recording chunk is not supported."
      )
    )
  }
}

/** */
export class RemoteRecordingChunked implements PersistentRecording {
  recordingId: string
  usesBackgroundOperations: boolean = false
  accessPath: string

  private storagePath: string
  private storageRef: StorageReference
  private contentType: string
  private info: RecordingSyncStatus
  private isClosed: boolean

  private chunks: RemoteChunk[]
  private currentChunkId: number

  private progressCallback: (status: RecordingSyncStatus) => void

  constructor(
    storage: FirebaseStorage,
    userId: string,
    recordingId: string,
    contentType: string,
    progressCallback?: (status: RecordingSyncStatus) => void
  ) {
    this.recordingId = recordingId
    this.isClosed = false

    const path = `users/${userId}/recordings/${recordingId}/${mimeTypeToFileName(contentType)}`
    this.storageRef = ref(storage, path)

    this.storagePath = this.storageRef.fullPath
    this.accessPath = this.storagePath

    this.contentType = contentType

    this.chunks = []
    this.currentChunkId = 0

    const createdAt = new Date()
    this.info = {
      kind: "recording-chunked",
      recordingId,
      bytesWritten: 0,
      bytesSynced: 0,
      totalBytes: 0,
      remotePath: this.storagePath,
      state: "init",
      createdAt,
      updatedAt: createdAt,
      contentType,
    }

    this.progressCallback = progressCallback ?? ((_s) => {})
  }

  /**
   * Upload a new recording
   */
  async write(data: Blob): Promise<void> {
    if (this.isClosed) {
      return Promise.reject(
        new PersistentRecordingError("Write failed, recording already closed")
      )
    }

    const chunk = new RemoteChunk(
      this.storageRef,
      this.recordingId,
      this.currentChunkId,
      (status) => {
        this.info = {
          ...this.info,
          bytesSynced: this.info.bytesSynced + status.bytesSynced,
        }
      }
    )

    this.chunks.push(chunk)

    const promise = await chunk.write(data)

    this.currentChunkId += 1

    this.info = {
      ...this.info,
      totalChunks: (this.info.totalChunks ?? 0) + 1,
      totalBytes: (this.info.totalBytes ?? 0) + data.size,
    }

    this.progressCallback(this.info)

    return promise
  }

  status(): Promise<RecordingSyncStatus> {
    return Promise.resolve(this.info)
  }

  async close(): Promise<void> {
    this.isClosed = true

    for (const chunk of this.chunks) {
      await chunk.close()
    }

    // update the remote metadata entry
    const metadataRef = ref(this.storageRef, "metadata.json")
    await uploadString(metadataRef, JSON.stringify(this.info), "raw", {
      contentType: "application/json",
    })
  }

  delete(): Promise<void> {
    return Promise.reject(
      new PersistentRecordingError(
        "Deleting a remote recording is not supported."
      )
    )
  }
}
