import * as React from "react"
import { useNavigate } from "react-router"
import {
  Button,
  Credenza as Dialog,
  CredenzaBody as DialogBody,
  CredenzaClose as DialogClose,
  CredenzaContent as DialogContent,
  CredenzaDescription as DialogDescription,
  CredenzaFooter as DialogFooter,
  CredenzaHeader as DialogHeader,
  CredenzaTitle as DialogTitle,
  CredenzaTrigger as DialogTrigger,
  Input,
  Label,
  toast,
} from "~/components/ui"
import { cn } from "~/components/ui/utils"
import { useDeleteClient } from "~/hooks/firestore/useClients"
import { PROMPTS } from "~/utils/prompts"

type DeleteClientModalProps = {
  clientId: string
  title?: React.ReactNode
  description?: React.ReactNode
  isOpen?: boolean
  onOpenChange?: (isOpen: boolean) => void
}

export function DeleteClientModal({
  children,
  clientId,
  isOpen,
  onOpenChange,
  title = "Delete Client?",
  description = PROMPTS.REMOVE_CLIENT,
}: React.PropsWithChildren<DeleteClientModalProps>) {
  const navigate = useNavigate()
  const [openConfirm, setOpenConfirm] = React.useState(false)
  const [isConfirmValid, setConfirmValid] = React.useState(false)

  const deleteClient = useDeleteClient({
    onSettled: (_, err) => {
      if (!err) {
        toast.info("Client deleted successfully")
        navigate("/notes")
      } else {
        toast.error("Error deleting client")
      }
    },
  })

  if (!clientId) return null

  return (
    <>
      <Dialog
        open={isOpen}
        onOpenChange={(open) => {
          if (deleteClient.isPending) return
          onOpenChange?.(open)
        }}
        shouldScaleBackground={false}
      >
        <DialogTrigger asChild>{children}</DialogTrigger>

        <DialogContent
          indicator={false}
          className={cn(
            "border-0 rounded-2xl after:!content-none inset-x-4 md:inset-x-auto bottom-4 md:bottom-auto"
          )}
        >
          <DialogHeader>
            <DialogTitle>{title}</DialogTitle>
            <DialogDescription>{description}</DialogDescription>
          </DialogHeader>

          <DialogFooter className="grid grid-cols-2">
            <Button
              variant="ghost"
              onClick={() => {
                setOpenConfirm(true)
              }}
            >
              Yes, delete notes
            </Button>

            <Button
              variant="destructive"
              isLoading={
                deleteClient.isPending &&
                deleteClient.variables?.mode === "unlink"
              }
              onClick={() => {
                deleteClient.mutate({ clientId, mode: "unlink" })
              }}
            >
              Yes, unlink notes
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openConfirm}
        onOpenChange={setOpenConfirm}
        shouldScaleBackground={false}
      >
        <DialogContent className="bg-primary-cream-300">
          <DialogHeader className="text-left">
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogDescription>
              This will delete all notes associated with this client. <br />
              Please enter <strong>DELETE</strong> to proceed.
            </DialogDescription>
          </DialogHeader>

          <DialogBody>
            <Label
              htmlFor="confirm"
              className="sr-only"
            >
              Confirm
            </Label>

            <Input
              id="confirm"
              className="block w-full"
              onChange={(e) => {
                setConfirmValid(e.target.value === "DELETE")
              }}
            />
          </DialogBody>

          <DialogFooter className="pt-2 grid grid-cols-2">
            <DialogClose asChild>
              <Button variant="outline">Cancel</Button>
            </DialogClose>

            <Button
              variant="destructive"
              disabled={!isConfirmValid}
              isLoading={
                deleteClient.isPending &&
                deleteClient.variables?.mode === "delete"
              }
              onClick={() => {
                deleteClient.mutate({ clientId, mode: "delete" })
              }}
            >
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  )
}
